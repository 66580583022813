import { tuiRound } from '@taiga-ui/cdk';
const BYTES_PER_KIB = 1024;
const BYTES_PER_MIB = 1024 * BYTES_PER_KIB;
function tuiFormatSize(units, size) {
  if (size === undefined) {
    return null;
  }
  if (size < BYTES_PER_KIB) {
    return `${size} ${units[0]}`;
  }
  if (size < BYTES_PER_MIB) {
    return `${(size / BYTES_PER_KIB).toFixed(0)} ${units[1]}`;
  }
  return `${tuiRound(size / BYTES_PER_MIB, 2).toLocaleString('ru-RU')} ${units[2]}`;
}
function tuiGetAcceptArray(accept) {
  return accept.toLowerCase().split(',').map(format => format.trim());
}

/**
 * Generated bundle index. Do not edit.
 */

export { tuiFormatSize, tuiGetAcceptArray };
