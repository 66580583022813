import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  ALWAYS_FALSE_HANDLER,
  TUI_DEFAULT_IDENTITY_MATCHER,
  TUI_DEFAULT_STRINGIFY,
  TuiDestroyService,
  TuiIdentityMatcher,
  tuiPure,
} from '@taiga-ui/cdk';
import {
  TuiDataListModule,
  TuiTextfieldControllerModule,
  TuiValueContentContext,
} from '@taiga-ui/core';
import {
  TuiDataListWrapperModule,
  TuiItemsHandlers,
  TuiSelectModule,
} from '@taiga-ui/kit';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';
import { takeUntil } from 'rxjs/operators';
import {
  AbstractFormControl,
  FORM_CONTROL_PROVIDERS,
} from '../../abstracts/form-control';
import { FormControlComponent } from '../form-control/form-control.component';
import { FormControlLabelComponent } from '../form-control-label/form-control-label.component';
@Component({
  selector: 'ffb-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService],
  viewProviders: FORM_CONTROL_PROVIDERS,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TuiDataListModule,
    TuiDataListWrapperModule,
    TuiTextfieldControllerModule,
    TuiSelectModule,
    FormControlComponent,
    FormControlLabelComponent,
  ],
})
export class FormSelectComponent<T>
  extends AbstractFormControl
  implements AfterContentInit
{
  private readonly destroy$ = inject(TuiDestroyService);

  @Input() items?: T[] | null = null;
  @Input() valueContent: PolymorpheusContent<TuiValueContentContext<T>> = '';
  @Input() itemContent: PolymorpheusContent<TuiValueContentContext<T>> = '';
  @Input() emptyContent: PolymorpheusContent = null;
  @Input() stringify: TuiItemsHandlers<T>['stringify'] = TUI_DEFAULT_STRINGIFY;
  @Input() identityMatcher: TuiIdentityMatcher<T> =
    TUI_DEFAULT_IDENTITY_MATCHER;
  @Input() disabledItemHandler: TuiItemsHandlers<T>['disabledItemHandler'] =
    ALWAYS_FALSE_HANDLER;
  @Input() clearable? = false;
  @Input() iconLeft?: null | string;
  @Input() fitMaxItemLabelWidth?: boolean;

  ngAfterContentInit() {
    super.ngAfterContentInit();

    if (!this.items) {
      if (
        this.formControlName === 'gender' ||
        this.formControlParentName === 'gender'
      ) {
        this.translocoService
          .selectTranslation('forms')
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.stringify = (item) =>
              this.translocoService.translate(
                `forms.fields.gender.values.${item}`,
              );
            this.items = ['F', 'M'] as T[];
          });
      } else if (
        this.formControlName === 'title' ||
        this.formControlParentName === 'title'
      ) {
        this.translocoService
          .selectTranslation('forms')
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.stringify = (item) =>
              this.translocoService.translate(
                `forms.fields.title.values.${item}`,
              );
            this.items = ['F', 'M'] as T[];
          });
      }
    }
  }

  @tuiPure
  getWidth(items?: ReadonlyArray<T> | null): string {
    if (!items?.length) {
      return 'auto';
    }

    return `calc(${
      this.stringify(
        [...items].sort(
          (a, b) => this.stringify(a).length - this.stringify(b).length,
        )[items.length - 1],
      ).length * 0.54
    }em + ${this.size === 'l' ? 4 : 3.5}rem)`;
  }
}
