import { NgTemplateOutlet } from '@angular/common';
import { Component, HostBinding } from '@angular/core';
import {
  TuiErrorModule,
  TuiInteractiveState,
  TuiLabelModule,
} from '@taiga-ui/core';
import { AbstractFormControl } from '../../abstracts/form-control';
import { FormControlLabelComponent } from '../form-control-label/form-control-label.component';

@Component({
  selector: 'ffb-form-control',
  templateUrl: './form-control.component.html',
  styleUrls: ['./form-control.component.scss'],
  standalone: true,
  imports: [
    NgTemplateOutlet,
    TuiLabelModule,
    TuiErrorModule,
    FormControlLabelComponent,
  ],
})
export class FormControlComponent extends AbstractFormControl {
  @HostBinding('attr.data-state')
  get interactiveState(): TuiInteractiveState | null | string {
    if (this.readOnly) {
      return TuiInteractiveState.Readonly;
    }

    return null;
  }
}
