import { ScrollingModule } from '@angular/cdk/scrolling';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  inject,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoDirective } from '@ngneat/transloco';
import {
  ALWAYS_FALSE_HANDLER,
  TUI_DEFAULT_IDENTITY_MATCHER,
  TUI_DEFAULT_MATCHER,
  TUI_DEFAULT_STRINGIFY,
  TUI_STRICT_MATCHER,
  TuiDestroyService,
  TuiElementModule,
  TuiIdentityMatcher,
  TuiStringMatcher,
  tuiIsNativeFocused,
  tuiPure,
} from '@taiga-ui/cdk';
import {
  TuiDataListModule,
  TuiPrimitiveTextfieldModule,
  TuiScrollbarModule,
  TuiTextfieldControllerModule,
  TuiValueContentContext,
} from '@taiga-ui/core';
import {
  TUI_COUNTRIES,
  TuiComboBoxModule,
  TuiDataListWrapperModule,
  TuiItemsHandlers,
} from '@taiga-ui/kit';
import {
  PolymorpheusContent,
  PolymorpheusModule,
} from '@tinkoff/ng-polymorpheus';
import { takeUntil } from 'rxjs/operators';
import { TCountryIsoCode } from '@lancelot-frontend/api';
import {
  AbstractFormControl,
  FORM_CONTROL_PROVIDERS,
} from '../../abstracts/form-control';
import { FormControlComponent } from '../form-control/form-control.component';
import { FormControlLabelComponent } from '../form-control-label/form-control-label.component';
import { TFormInputAutocomplete } from '../form-input/form-input.component';

@Component({
  selector: 'ffb-form-combo-box',
  templateUrl: './form-combo-box.component.html',
  styleUrls: ['./form-combo-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService],
  viewProviders: FORM_CONTROL_PROVIDERS,
  standalone: true,
  imports: [
    ScrollingModule,
    ReactiveFormsModule,
    TranslocoDirective,
    PolymorpheusModule,
    TuiElementModule,
    TuiScrollbarModule,
    TuiTextfieldControllerModule,
    TuiPrimitiveTextfieldModule,
    TuiDataListWrapperModule,
    TuiDataListModule,
    TuiComboBoxModule,
    FormControlComponent,
    FormControlLabelComponent,
  ],
})
export class FormComboBoxComponent<T>
  extends AbstractFormControl
  implements AfterContentInit
{
  private readonly destroy$ = inject(TuiDestroyService);

  get type() {
    return 'search';
  }

  search: null | string = '';
  countriesNames$ = inject(TUI_COUNTRIES);
  countriesNames!: Record<TCountryIsoCode, string>;

  @Input() items?: T[] | null = null;
  @Input() valueContent: PolymorpheusContent<TuiValueContentContext<T>> = ``;
  @Input() itemContent: PolymorpheusContent<TuiValueContentContext<T>> = ``;
  @Input() emptyContent: PolymorpheusContent = null;
  @Input() stringify: TuiItemsHandlers<T>['stringify'] = TUI_DEFAULT_STRINGIFY;
  @Input() identityMatcher: TuiIdentityMatcher<T> =
    TUI_DEFAULT_IDENTITY_MATCHER;
  @Input() strictMatcher: TuiStringMatcher<T> = TUI_STRICT_MATCHER;
  @Input() itemMatcher: TuiStringMatcher<T> = TUI_DEFAULT_MATCHER;
  @Input() disabledItemHandler: TuiItemsHandlers<T>['disabledItemHandler'] =
    ALWAYS_FALSE_HANDLER;
  @Input() iconLeft?: null | string;
  @Input() autocomplete?: TFormInputAutocomplete;
  @Input() clearable? = false;
  @Input() withVirtualScroll? = false;

  @Output() searchChange = new EventEmitter<null | string>();

  ngAfterContentInit() {
    super.ngAfterContentInit();

    if (!this.items) {
      if (
        this.formControlName === 'country' ||
        this.formControlParentName === 'country'
      ) {
        this.stringify = (item) => this.countriesNames[item as TCountryIsoCode];
        this.countriesNames$
          .pipe(takeUntil(this.destroy$))
          .subscribe((countriesNames) => {
            this.countriesNames = countriesNames;
            this.items = Object.keys(countriesNames).sort((a, b) =>
              this.countriesNames[a as TCountryIsoCode].localeCompare(
                this.countriesNames[b as TCountryIsoCode],
              ),
            ) as T[];
          });
      } else if (
        this.formControlName === 'nationality' ||
        this.formControlParentName === 'nationality'
      ) {
        this.translocoService
          .selectTranslation('forms')
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.stringify = (item) =>
              this.translocoService.translate(
                `forms.fields.nationality.values.${item}`,
              );
            this.items = Object.values(TCountryIsoCode).sort((a, b) =>
              this.stringify(a as T).localeCompare(this.stringify(b as T)),
            ) as T[];
          });
      }
    }
  }

  getContext(
    $implicit: T,
    { nativeElement }: ElementRef<HTMLElement>,
  ): TuiValueContentContext<T> {
    return { $implicit, active: tuiIsNativeFocused(nativeElement) };
  }

  onSearchChange(search: null | string): void {
    this.search = search;
    this.searchChange.emit(search);
  }

  extractValueFromEvent(event: Event): null | string {
    return (event.target as HTMLInputElement)?.value || null;
  }

  @tuiPure
  filter(items: T[] | null | undefined, search: null | string) {
    return (
      items?.filter((item) =>
        this.itemMatcher(item, search || '', this.stringify),
      ) ?? null
    );
  }
}
