import { ScrollingModule } from '@angular/cdk/scrolling';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoDirective } from '@ngneat/transloco';
import {
  ALWAYS_FALSE_HANDLER,
  ALWAYS_TRUE_HANDLER,
  TUI_DEFAULT_IDENTITY_MATCHER,
  TUI_DEFAULT_MATCHER,
  TUI_DEFAULT_STRINGIFY,
  TuiBooleanHandler,
  TuiElementModule,
  TuiIdentityMatcher,
  TuiStringMatcher,
  tuiIsNativeFocused,
  tuiPure,
} from '@taiga-ui/cdk';
import {
  TuiDataListModule,
  TuiPrimitiveTextfieldModule,
  TuiScrollbarModule,
  TuiTextfieldControllerModule,
  TuiValueContentContext,
} from '@taiga-ui/core';
import {
  TuiDataListWrapperModule,
  TuiItemsHandlers,
  TuiMultiSelectModule,
} from '@taiga-ui/kit';
import {
  PolymorpheusContent,
  PolymorpheusModule,
} from '@tinkoff/ng-polymorpheus';
import {
  AbstractFormControl,
  FORM_CONTROL_PROVIDERS,
} from '../../abstracts/form-control';
import { FormControlComponent } from '../form-control/form-control.component';
import { FormControlLabelComponent } from '../form-control-label/form-control-label.component';
import { TFormInputAutocomplete } from '../form-input/form-input.component';

@Component({
  selector: 'ffb-form-multi-select',
  templateUrl: './form-multi-select.component.html',
  styleUrls: ['./form-multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: FORM_CONTROL_PROVIDERS,
  standalone: true,
  imports: [
    TranslocoDirective,
    ScrollingModule,
    ReactiveFormsModule,
    PolymorpheusModule,
    TuiElementModule,
    TuiScrollbarModule,
    TuiTextfieldControllerModule,
    TuiPrimitiveTextfieldModule,
    TuiDataListWrapperModule,
    TuiDataListModule,
    TuiMultiSelectModule,
    FormControlComponent,
    FormControlLabelComponent,
  ],
})
export class FormMultiSelectComponent<T> extends AbstractFormControl {
  get type() {
    return 'search';
  }

  search: null | string = '';

  @Input() items?: T[] | null = null;
  @Input() valueContent: PolymorpheusContent<TuiValueContentContext<T>> = '';
  @Input() itemContent: PolymorpheusContent<TuiValueContentContext<T>> = '';
  @Input() emptyContent: PolymorpheusContent = null;
  @Input() stringify: TuiItemsHandlers<T>['stringify'] = TUI_DEFAULT_STRINGIFY;
  @Input() identityMatcher: TuiIdentityMatcher<T> =
    TUI_DEFAULT_IDENTITY_MATCHER;
  @Input() itemMatcher: TuiStringMatcher<T> = TUI_DEFAULT_MATCHER;
  @Input() tagValidator: TuiBooleanHandler<T> = ALWAYS_TRUE_HANDLER;
  @Input() disabledItemHandler: TuiItemsHandlers<T>['disabledItemHandler'] =
    ALWAYS_FALSE_HANDLER;
  @Input() iconLeft?: null | string;
  @Input() autocomplete?: TFormInputAutocomplete;
  @Input() clearable? = false;
  @Input() autoColor? = false;
  @Input() withVirtualScroll? = false;

  @Output() searchChange = new EventEmitter<null | string>();

  getContext(
    $implicit: T,
    { nativeElement }: ElementRef<HTMLElement>,
  ): TuiValueContentContext<T> {
    return { $implicit, active: tuiIsNativeFocused(nativeElement) };
  }

  onSearchChange(search: null | string): void {
    this.search = search;
    this.searchChange.emit(search);
  }

  @tuiPure
  filter(items: T[] | null | undefined, search: null | string) {
    return (
      items?.filter((item) =>
        this.itemMatcher(item, search || '', this.stringify),
      ) ?? null
    );
  }
}
