import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  PolymorpheusContent,
  PolymorpheusModule,
} from '@tinkoff/ng-polymorpheus';

@Component({
  selector: 'ffb-form-control-label',
  templateUrl: './form-control-label.component.html',
  styleUrls: ['./form-control-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PolymorpheusModule],
})
export class FormControlLabelComponent {
  @Input() label: PolymorpheusContent;
  @Input() required = false;
}
