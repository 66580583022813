<ng-container *transloco="let t">
  <form [attr.data-test-id]="name">
    <!--  title -->
    @if (title) {
      <h3
        class="tui-form__header tui-form__header_margin-top_none"
        attr.data-test-id="{{ name }}-title"
      >
        {{ title }}
      </h3>
    }

    <div class="left-align">
      <!--  inputs -->
      <fieldset [disabled]="readOnly">
        <ng-content></ng-content>
      </fieldset>

      <!--  required fields note -->
      @if (displayRequiredFieldsNote && hasRequiredFields) {
        <p class="tui-form__field-note pl2 mt2">
          {{ t('forms.notes.requiredField') }}
        </p>
      }

      @if (successMessage || error || errorMessage) {
        <tui-notification
          [status]="successMessage ? 'success' : 'error'"
          class="mt2"
        >
          <div class="pre-line">
            <ng-container
              *polymorpheusOutlet="
                successMessage ||
                errorMessage ||
                t('forms.errors.' + (isValidationError(error) ? 'validationFailed' : 'unknown')) as text
              "
            >
              {{ text }}
            </ng-container>
          </div>
        </tui-notification>
      }

      <!--  submit button -->
      @if (submitButton || cancel.observers.length > 0) {
        <div
          class="tui-form__buttons"
          [class.tui-form__buttons_align_end]="buttonsAlignment === 'right'"
          [class.tui-form__buttons_align_center]="buttonsAlignment === 'center'"
        >
          @if (cancel.observers.length > 0) {
            <button
              tuiButton
              type="button"
              appearance="outline"
              class="tui-form__button"
              [size]="buttonsSize"
              attr.data-test-id="{{ name }}-cancel-button"
              [disabled]="loading"
              (click)="cancel.emit()"
              [class.tui-skeleton]="skeleton"
            >
              {{ cancelButtonLabel || t('forms.actions.cancel') }}
            </button>
          }
          @if (submitButton) {
            <button
              tuiButton
              type="submit"
              class="tui-form__button"
              [size]="buttonsSize"
              attr.data-test-id="{{ name }}-submit-button"
              [disabled]="
                loading || (!form?.valid && !hasUpdateOnSubmitControls)
              "
              [showLoader]="loading"
              (click)="onSubmit()"
              [class.tui-skeleton]="skeleton"
            >
              {{ submitButtonLabel || t('forms.actions.validate') }}
            </button>
          }
        </div>
      }
    </div>
  </form>
</ng-container>
