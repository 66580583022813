<ng-container *transloco="let t">
  <ffb-form-control
    [formControl]="ngControl?.control"
    [type]="type"
    [note]="note"
    [label]="label"
    [labelOutside]="labelOutside"
    [required]="required"
    >
    <tui-input-date
      [formControl]="ngControl?.control"
      [min]="min"
      [max]="max"
      [items]="items"
      [disabledItemHandler]="disabledItemHandler"
      [markerHandler]="markerHandler"
      [readOnly]="readOnly"
      [tuiTextfieldCleaner]="clearable"
      [tuiTextfieldSize]="size"
      [tuiTextfieldLabelOutside]="labelOutside"
      (focusedChange)="focusedChange.emit($event)"
      [attr.data-test-id]="formControlName"
      >
      @if (!labelOutside) {
        <ffb-form-control-label
          [label]="label"
          [required]="required"
        ></ffb-form-control-label>
      }
      <input tuiTextfield [attr.placeholder]="example" data-test-id="input" />
    </tui-input-date>
  </ffb-form-control>
</ng-container>
