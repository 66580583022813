<ffb-form-control
  [formControl]="ngControl?.control"
  [type]="type"
  [note]="note"
  [label]="label"
  [required]="required"
  >
  @if (label) {
    <label class="cursor-pointer">
      <ffb-form-control-label
        [label]="label"
        [required]="required"
      ></ffb-form-control-label>
      <div>
        <ng-container [ngTemplateOutlet]="toggle"></ng-container>
      </div>
    </label>
  } @else {
    <ng-container [ngTemplateOutlet]="toggle"></ng-container>
  }

  <ng-template #toggle>
    <tui-toggle
      [formControl]="ngControl?.control"
      [readOnly]="readOnly"
      [size]="size"
      [attr.data-test-id]="formControlName"
      >
    </tui-toggle>
  </ng-template>
</ffb-form-control>
