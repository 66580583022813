<ffb-form-control
  [formControl]="ngControl?.control"
  [type]="type"
  [note]="note"
>
  <tui-checkbox-labeled
    [formControl]="ngControl?.control"
    [attr.data-test-id]="formControlName"
  >
    <ffb-form-control-label
      [label]="label"
      [required]="required"
    ></ffb-form-control-label>
  </tui-checkbox-labeled>
</ffb-form-control>
