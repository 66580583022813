import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TUI_DEFAULT_STRINGIFY } from '@taiga-ui/cdk';
import { TuiLabelModule, TuiValueContentContext } from '@taiga-ui/core';
import { TuiItemsHandlers, TuiRadioLabeledModule } from '@taiga-ui/kit';
import {
  PolymorpheusContent,
  PolymorpheusModule,
} from '@tinkoff/ng-polymorpheus';
import {
  AbstractFormControl,
  FORM_CONTROL_PROVIDERS,
} from '../../abstracts/form-control';
import { FormControlComponent } from '../form-control/form-control.component';
import { FormControlLabelComponent } from '../form-control-label/form-control-label.component';

@Component({
  selector: 'ffb-form-radio-list',
  templateUrl: './form-radio-list.component.html',
  styleUrls: ['./form-radio-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: FORM_CONTROL_PROVIDERS,
  standalone: true,
  imports: [
    NgTemplateOutlet,
    ReactiveFormsModule,
    PolymorpheusModule,
    TuiLabelModule,
    TuiRadioLabeledModule,
    FormControlComponent,
    FormControlLabelComponent,
  ],
})
export class FormRadioListComponent<T> extends AbstractFormControl {
  @Input() items?: T[] = [];
  @Input() itemContent: PolymorpheusContent<TuiValueContentContext<T>> = '';
  @Input() stringify: TuiItemsHandlers<T>['stringify'] = TUI_DEFAULT_STRINGIFY;
}
