  @if (labelOutside) {
    <label [tuiLabel]="labelTemplate">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </label>
  } @else {
    <ng-container *ngTemplateOutlet="content"></ng-container>
  }
  <div class="pl2">
    <!--  note -->
    @if (note) {
      <span
        class="tui-form__field-note"
        attr.data-test-id="{{ formControlName }}-input-note"
        [innerHTML]="note"
      >
      </span>
    }

    <!--  errors -->
    @for (error of errors; track error) {
      <tui-error
        [error]="error"
        attr.data-test-id="{{ formControlName }}-input-error"
      ></tui-error>
    }
  </div>


<ng-template #labelTemplate>
  <ffb-form-control-label
    [label]="label"
    [required]="required"
  ></ffb-form-control-label>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
