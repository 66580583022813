import { NgTemplateOutlet } from '@angular/common';
import {
  Component,
  DoCheck,
  Input,
  OnInit,
  TemplateRef,
  inject,
} from '@angular/core';
import {
  FormGroup,
  FormGroupDirective,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiLetModule } from '@taiga-ui/cdk';
import {
  TuiButtonModule,
  TuiDialogModule,
  TuiExpandModule,
  TuiLinkModule,
} from '@taiga-ui/core';
import { TuiTagModule } from '@taiga-ui/kit';
import { ObjectKeys, ShowHideDirective } from '@lancelot-frontend/core';
import { FORM_CONTROL_PROVIDERS } from '../../abstracts/form-control';
import { FormComponent } from '../form/form.component';
import { FormInputComponent } from '../form-input/form-input.component';

@Component({
  selector: 'ffb-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss'],
  viewProviders: FORM_CONTROL_PROVIDERS,
  standalone: true,
  imports: [
    TranslocoDirective,
    FormComponent,
    FormsModule,
    ReactiveFormsModule,
    NgTemplateOutlet,
    FormInputComponent,
    TuiLetModule,
    TuiTagModule,
    TuiExpandModule,
    TuiButtonModule,
    TuiLinkModule,
    ObjectKeys,
    ShowHideDirective,
    TuiDialogModule,
  ],
})
export class SearchFormComponent implements OnInit, DoCheck {
  private formGroupDirective = inject(FormGroupDirective, {
    optional: true,
    self: true,
  });

  protected form?: FormGroup;

  @Input({ required: true }) name!: string;
  @Input({ required: true }) searchInputControlName!: string;
  @Input() searchInputTemplate?: TemplateRef<unknown>;
  @Input() searchInputLabel?: string;
  @Input() advancedSearchControls?: TemplateRef<unknown>;
  @Input() reset?: () => void;
  @Input() tagHandler: (
    controlName: string,
    controlValue: unknown,
  ) => false | null | string = (controlName) => controlName;

  expanded = false;
  tags: Record<string, string> = {};

  ngOnInit() {
    this.form = this.formGroupDirective?.form;
  }

  ngDoCheck() {
    const tags: Record<string, string> = {};

    Object.keys(this.form?.controls ?? {}).forEach(
      (controlOrControlsGroupName) => {
        const controlOrControlsGroup = this.form?.get(
          controlOrControlsGroupName,
        );

        if (controlOrControlsGroup) {
          if (
            // @ts-expect-error controls does exist on FormGroup
            !controlOrControlsGroup.controls &&
            controlOrControlsGroupName !== this.searchInputControlName
          ) {
            const tagValue = this.tagHandler(
              controlOrControlsGroupName,
              controlOrControlsGroup.value,
            );
            if (tagValue) {
              tags[controlOrControlsGroupName] = tagValue;
            }
          }
          // @ts-expect-error controls does exist on FormGroup
          else if (controlOrControlsGroup.controls) {
            // @ts-expect-error controls does exist on FormGroup
            Object.keys(controlOrControlsGroup.controls).forEach(
              (controlName) => {
                if (controlName !== this.searchInputControlName) {
                  const control = controlOrControlsGroup.get(controlName);
                  const tagValue =
                    control &&
                    this.tagHandler(
                      controlOrControlsGroupName + '.' + controlName,
                      control.value,
                    );
                  if (tagValue) {
                    tags[controlOrControlsGroupName + '.' + controlName] =
                      tagValue;
                  }
                }
              },
            );
          }
        }
      },
    );

    this.tags = tags;
  }

  removeTag(controlName: string) {
    this.form?.get(controlName)?.reset();
  }

  toggle() {
    this.expanded = !this.expanded;
  }
}
