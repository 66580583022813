import { ControlValueAccessor } from '@angular/forms';

export const NOOP_VALUE_ACCESSOR: ControlValueAccessor = {
  writeValue(): void {
    // do nothing.
  },
  registerOnChange(): void {
    // do nothing.
  },
  registerOnTouched(): void {
    // do nothing.
  },
};
