import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiBooleanHandler } from '@taiga-ui/cdk';
import {
  TuiPrimitiveTextfieldModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import { TuiInputTagModule } from '@taiga-ui/kit';
import {
  AbstractFormControl,
  FORM_CONTROL_PROVIDERS,
} from '../../abstracts/form-control';
import { FormControlComponent } from '../form-control/form-control.component';
import { FormControlLabelComponent } from '../form-control-label/form-control-label.component';

@Component({
  selector: 'ffb-form-input-tag',
  templateUrl: './form-input-tag.component.html',
  styleUrls: ['./form-input-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: FORM_CONTROL_PROVIDERS,
  standalone: true,
  imports: [
    TranslocoDirective,
    FormControlComponent,
    FormsModule,
    ReactiveFormsModule,
    TuiInputTagModule,
    TuiTextfieldControllerModule,
    FormControlLabelComponent,
    TuiPrimitiveTextfieldModule,
  ],
})
export class FormInputTagComponent extends AbstractFormControl {
  @Input() maxLength?: number;
  @Input() uniqueTags = false;
  @Input() editable = false;
  @Input() expandable = true;
  @Input() separator: RegExp | string = ',';
  @Input() rows = 100;
  @Input() tagValidator: TuiBooleanHandler<string> = () => !0;
  @Input() clearable = true;
}
