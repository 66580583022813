<ng-container *transloco="let t">
  <ffb-form [name]="name" [formGroup]="form" [submitButton]="false">
    <!--  Text input    -->
    <div class="flex flex-row items-center justify-center gap1">
      <div class="flex-auto">
        @if (searchInputTemplate) {
          <ng-container *ngTemplateOutlet="searchInputTemplate"></ng-container>
        } @else {
          <ffb-form-input
            class="full-width"
            [formControlName]="searchInputControlName"
            [label]="searchInputLabel"
            [clearable]="true"
            type="search"
          ></ffb-form-input>
        }
      </div>
      @if (advancedSearchControls) {
        <button
          tuiIconButton
          icon="tuiIconSlidersLarge"
          appearance="primary-outline"
          (click)="toggle()"
          attr.data-test-id="{{ name }}-advanced-search-button"
        ></button>
      }
    </div>

    <ng-content></ng-content>

    @if (advancedSearchControls) {
      <!--  tags    -->
      @if (!expanded) {
        <div class="flex flex-row flex-wrap column-gap1">
          @for (controlName of tags | objectKeys; track controlName) {
            <div class="mt1">
              <tui-tag
                status="primary"
                [value]="tags[controlName]"
                (edited)="removeTag(controlName)"
                [removable]="true"
              >
              </tui-tag>
            </div>
          }
        </div>
      }
      <!--  Advanced search controls    -->
      <tui-expand
        *hide.ltSm="true"
        [expanded]="expanded"
        attr.data-test-id="{{ name }}-advanced-search-panel"
      >
        <ng-template tuiExpandContent>
          <div class="pt3 pb2">
            <ng-container
              *ngTemplateOutlet="advancedSearchControls"
            ></ng-container>
          </div>
        </ng-template>
      </tui-expand>
      <ng-template
        *show.ltSm="true"
        let-observer
        [tuiDialogOptions]="{ size: 'page' }"
        [(tuiDialog)]="expanded"
      >
        <h4 class="tui-text_h4 center py4">
          {{ t('forms.actions.advancedSearch') }}
        </h4>
        <ng-container *ngTemplateOutlet="advancedSearchControls"></ng-container>
        <div class="center">
          <div class="my3">
            <ng-container *ngTemplateOutlet="resetButton"></ng-container>
          </div>
          <button tuiButton (click)="observer.complete()">
            {{ t('forms.actions.seeResults') }}
          </button>
        </div>
      </ng-template>
      @if (expanded) {
        <div class="flex flex-row justify-center items-center gap3 mt2">
          <ng-container *ngTemplateOutlet="resetButton"></ng-container>
          <button
            tuiButton
            type="button"
            icon="tuiIconClose"
            appearance="icon"
            size="s"
            class="lowercase font-weight-normal"
            (click)="toggle()"
            attr.data-test-id="{{ name }}-close-advanced-search-button"
          >
            {{ t('actions.close') }}
          </button>
        </div>
      }
    }
  </ffb-form>

  <ng-template #resetButton>
    <button
      tuiButton
      type="button"
      icon="tuiIconRefreshCw"
      appearance="icon"
      size="s"
      class="lowercase font-weight-normal"
      (click)="reset ? reset() : form?.reset()"
      attr.data-test-id="{{ name }}-reset-button"
    >
      {{ t('forms.actions.reset') }}
    </button>
  </ng-template>
</ng-container>
