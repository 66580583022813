import { tuiAssert, tuiIsSafari, CHAR_HYPHEN, tuiIsNativeFocused, tuiGetDocumentOrShadowRoot, CHAR_NO_BREAK_SPACE, CHAR_EN_DASH } from '@taiga-ui/cdk';
import { MASK_CARET_TRAP, TUI_DIGIT_REGEXP, TUI_NON_DIGITS_REGEXP, TUI_LEADING_ZEROES_REGEXP, TUI_DECIMAL_SYMBOLS } from '@taiga-ui/core/constants';
import { tuiOtherDecimalSymbol } from '@taiga-ui/core/utils/format';
function addDecimalSymbolIfNeeded(value, decimalSymbol = ',') {
  return !value.includes(decimalSymbol) ? value + decimalSymbol : value;
}
function calculateSafariCaret(previousValue = '', current, previousCaret, decimalSymbol = ',') {
  const tailRegex = new RegExp(`${decimalSymbol}.+`);
  const previousWithoutTail = previousValue.replace(tailRegex, '');
  const currentWithoutTail = current.replace(tailRegex, '');
  const pasteOrCutOperation = Math.abs(previousWithoutTail.length - currentWithoutTail.length) > 2;
  if (pasteOrCutOperation) {
    return current.length;
  }
  if (previousValue.length === current.length) {
    if (previousValue.indexOf(decimalSymbol) <= previousCaret) {
      return calculateChangedTailIndex(previousValue, current);
    }
    return previousWithoutTail === currentWithoutTail ? previousCaret - 1 : previousCaret + 1;
  }
  if (previousValue.length === 0) {
    return 1;
  }
  const changeLength = current.length - previousValue.length;
  return previousCaret + changeLength;
}
function calculateChangedTailIndex(previous, current) {
  for (let i = 0; i < current.length; i++) {
    if (previous[i] !== current[i]) {
      return current[i] === '0' ? i : i + 1;
    }
  }
  return current.length;
}
function calculateCaretGap(previousValue = '', current, thousandSymbol) {
  const pasteOrCutOperation = Math.abs(previousValue.length - current.length) > 2;
  if (pasteOrCutOperation) {
    return 0;
  }
  const wereSpaces = previousValue.split(thousandSymbol).length;
  const nowSpaces = current.split(thousandSymbol).length;
  return nowSpaces - wereSpaces;
}
/**
 * TODO: delete in v4.0
 * @deprecated Use {@link https://maskito.dev/kit/number Number} from {@link https://github.com/taiga-family/maskito Maskito} instead <br/>
 * Used to finish a number with zeros to a given precision
 */
function tuiCreateAutoCorrectedNumberPipe(decimalLimit = 0, decimalSymbol = ',', thousandSymbol = CHAR_NO_BREAK_SPACE, nativeInput, allowNegative, isIOS = false) {
  ngDevMode && tuiAssert.assert(decimalLimit >= 0);
  // Guess for which browser I need this :)
  let previousCaret = -1;
  const unlucky = !!nativeInput && tuiIsSafari(nativeInput) || isIOS;
  if (nativeInput && unlucky) {
    nativeInput.addEventListener('beforeinput', () => {
      previousCaret = nativeInput.selectionStart || 0;
    });
  }
  return (conformedValue, config) => {
    // Removing everything by selecting and pressing '-'
    if (!conformedValue && config.rawValue === CHAR_HYPHEN && allowNegative) {
      return CHAR_HYPHEN;
    }
    // remove these hacks after text mask library has changed
    if (nativeInput && unlucky && tuiIsNativeFocused(nativeInput)) {
      const caret = calculateSafariCaret(config.previousConformedValue, conformedValue, previousCaret);
      setTimeout(() => {
        nativeInput.setSelectionRange(caret, caret);
      });
    }
    if (nativeInput && nativeInput.ownerDocument !== tuiGetDocumentOrShadowRoot(nativeInput) && tuiIsNativeFocused(nativeInput) && config.currentCaretPosition) {
      const realCaretPosition = config.currentCaretPosition + calculateCaretGap(config.previousConformedValue, conformedValue, thousandSymbol);
      setTimeout(() => {
        nativeInput.setSelectionRange(realCaretPosition, realCaretPosition);
      });
    }
    if (conformedValue === '' || !decimalLimit || !Number.isInteger(decimalLimit)) {
      return {
        value: conformedValue
      };
    }
    const withDecimalSymbol = addDecimalSymbolIfNeeded(conformedValue, decimalSymbol);
    const decimalPart = withDecimalSymbol.split(decimalSymbol)[1];
    const zeroPaddingSize = decimalLimit - decimalPart.length;
    return {
      value: withDecimalSymbol + '0'.repeat(zeroPaddingSize)
    };
  };
}
const ASSERTION = 'Correction function must return single char or null';
/**
 * TODO: delete in v4.0
 * @deprecated Use {@link https://maskito.dev/core-concepts/processors processors} from {@link https://github.com/taiga-family/maskito Maskito}
 */
function tuiCreateCorrectionMask(allowed, correctionHandler) {
  return rawValue => {
    const mask = rawValue.split('').reduce((result, char, index) => {
      const corrected = correctionHandler(char, index);
      ngDevMode && tuiAssert.assert(corrected === null || corrected.length === 1, ASSERTION);
      if (!allowed.test(char) && !corrected) {
        return result;
      }
      if (allowed.test(char)) {
        return [...result, allowed];
      }
      if (corrected) {
        return [...result, corrected, MASK_CARET_TRAP];
      }
      return result;
    }, []);
    const lastIndex = mask.lastIndexOf(MASK_CARET_TRAP);
    const filtered = mask.filter((item, index) => item !== MASK_CARET_TRAP || index === lastIndex);
    return filtered.some(item => item !== allowed) ? [...filtered, allowed] : filtered;
  };
}
const NON_ZERO_DIGIT = /[1-9]/;
function preventLeadingZeroes(mask, isOnlyZeroDigit = false, leadingZerosAmount = 0) {
  if (isOnlyZeroDigit || leadingZerosAmount === 0) {
    return mask;
  }
  const firstDigitIndex = mask.indexOf(TUI_DIGIT_REGEXP);
  if (firstDigitIndex === -1) {
    return mask;
  }
  const secondMaskDigit = mask[firstDigitIndex + 1];
  const isCaretTrap = secondMaskDigit === MASK_CARET_TRAP;
  if (isCaretTrap && leadingZerosAmount === 1) {
    return mask;
  }
  if (isCaretTrap) {
    mask.unshift(NON_ZERO_DIGIT);
    return mask;
  }
  mask[firstDigitIndex] = NON_ZERO_DIGIT;
  return mask;
}
function getDecimalSymbolIndex(str, decimalSymbol, autoCorrectDecimalSymbol) {
  if (!autoCorrectDecimalSymbol) {
    return str.lastIndexOf(decimalSymbol);
  }
  return Math.max(str.lastIndexOf(decimalSymbol), str.lastIndexOf(tuiOtherDecimalSymbol(decimalSymbol)));
}
function isDecimalSymbol(str, decimalSymbol, autoCorrectDecimalSymbol) {
  if (autoCorrectDecimalSymbol) {
    return /^[,.]$/.test(str);
  }
  return str === decimalSymbol;
}
function convertToMask(strNumber) {
  return strNumber.split('').map(char => TUI_DIGIT_REGEXP.test(char) ? TUI_DIGIT_REGEXP : char);
}
function addThousandsSeparator(strNumber, thousandSymbol) {
  return strNumber.length > 3 ?
  // TODO: investigate to disallow potentially catastrophic exponential-time regular expressions.
  // eslint-disable-next-line unicorn/no-unsafe-regex
  strNumber.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSymbol) : strNumber;
}
/**
 * TODO: delete in v4.0
 * @deprecated Use {@link https://maskito.dev/kit/number Number} from {@link https://github.com/taiga-family/maskito Maskito} instead <br/>
 * Adaptation for {@link https://github.com/text-mask/text-mask/tree/master/addons#createnumbermask `createNumberMask`}
 */
function tuiCreateNumberMask({
  allowDecimal = false,
  decimalSymbol = ',',
  thousandSymbol = CHAR_NO_BREAK_SPACE,
  autoCorrectDecimalSymbol = true,
  decimalLimit = 2,
  requireDecimal = false,
  allowNegative = false,
  integerLimit = 0
} = {}) {
  ngDevMode && tuiAssert.assert(decimalLimit >= 0);
  ngDevMode && tuiAssert.assert(Number.isInteger(integerLimit));
  ngDevMode && tuiAssert.assert(integerLimit >= 0);
  return (rawValue, {
    previousConformedValue
  }) => {
    if (previousConformedValue && requireDecimal) {
      const conformedWithoutSeparator = rawValue.split(thousandSymbol).join('');
      const previousConformedValueWithoutDecimalSymbolAndSeparator = previousConformedValue.split(thousandSymbol).join('').split(decimalSymbol).join('');
      // Forbid removal of decimal separator if decimal part is required
      if (conformedWithoutSeparator === previousConformedValueWithoutDecimalSymbolAndSeparator) {
        rawValue = previousConformedValue;
      }
    }
    const isNegative = ((rawValue === null || rawValue === void 0 ? void 0 : rawValue.startsWith(CHAR_HYPHEN)) || (rawValue === null || rawValue === void 0 ? void 0 : rawValue.startsWith(CHAR_EN_DASH))) && allowNegative;
    if (isDecimalSymbol(rawValue, decimalSymbol, autoCorrectDecimalSymbol) && allowDecimal) {
      return ['0', decimalSymbol, TUI_DIGIT_REGEXP];
    }
    if (isNegative) {
      rawValue = rawValue.slice(1);
    }
    const decimalIndex = getDecimalSymbolIndex(rawValue, decimalSymbol, autoCorrectDecimalSymbol);
    const hasDecimal = decimalIndex !== -1;
    const integer = hasDecimal ? rawValue.slice(0, decimalIndex) : rawValue;
    const thousandSeparators = integer.match(new RegExp(thousandSymbol, 'g')) || [];
    const integerCapped = integerLimit ? integer.slice(0, integerLimit + thousandSeparators.length) : integer;
    const integerCappedClean = integerCapped.replace(TUI_NON_DIGITS_REGEXP, '');
    const [leadingZerosMatch] = integerCappedClean.match(TUI_LEADING_ZEROES_REGEXP) || [''];
    const leadingZerosAmount = leadingZerosMatch.length;
    const integerCappedZerosClean = integerCappedClean.replace(/^0+(?!\.|$)/, '').trim();
    const withSeparator = addThousandsSeparator(integerCappedZerosClean, thousandSymbol);
    const mask = convertToMask(withSeparator);
    if (hasDecimal && allowDecimal || requireDecimal) {
      const fraction = hasDecimal ? convertToMask(rawValue.slice(decimalIndex + 1).replace(TUI_NON_DIGITS_REGEXP, '')) : [];
      const fractionCapped = decimalLimit ? fraction.slice(0, decimalLimit) : fraction;
      if (rawValue[decimalIndex] !== tuiOtherDecimalSymbol(decimalSymbol)) {
        mask.push(MASK_CARET_TRAP);
      }
      mask.push(decimalSymbol, MASK_CARET_TRAP, ...fractionCapped);
      for (let i = 0; i < Math.min(decimalLimit - fractionCapped.length, 20); i++) {
        mask.push(TUI_DIGIT_REGEXP);
      }
    }
    const isOnlyZeroDigit = mask.length === 1 && integerCappedZerosClean === '0';
    if (isNegative) {
      if (mask.length === 0) {
        mask.push(TUI_DIGIT_REGEXP);
      }
      mask.unshift(CHAR_HYPHEN);
    }
    return preventLeadingZeroes(mask, isOnlyZeroDigit, leadingZerosAmount);
  };
}

/**
 * TODO: delete in v4.0
 * @deprecated Use {@link https://maskito.dev/kit/number Number} from {@link https://github.com/taiga-family/maskito Maskito} instead
 */
function tuiEnableAutoCorrectDecimalSymbol({
  thousandSeparator
}) {
  return !TUI_DECIMAL_SYMBOLS.includes(thousandSeparator);
}

/**
 * TODO: delete in v4.0
 * @deprecated use {@link https://maskito.dev/kit/number maskitoParseNumber} instead
 * ```ts
 * Number.isNaN(maskitoParseNumber(value, decimalSeparator))
 * ```
 */
function tuiMaskedMoneyValueIsEmpty(value) {
  switch (value) {
    case '':
    case CHAR_HYPHEN:
    case ',':
    case `${CHAR_HYPHEN},`:
      return true;
    default:
      return false;
  }
}

/**
 * TODO: delete in v4.0
 * @deprecated use {@link https://maskito.dev/kit/number maskitoParseNumber} instead
 */
function tuiMaskedNumberStringToNumber(value, decimalsSymbol, thousandSymbol) {
  return parseFloat(value.split(thousandSymbol).join('').split(decimalsSymbol).join('.'));
}

/**
 * Generated bundle index. Do not edit.
 */

export { tuiCreateAutoCorrectedNumberPipe, tuiCreateCorrectionMask, tuiCreateNumberMask, tuiEnableAutoCorrectDecimalSymbol, tuiMaskedMoneyValueIsEmpty, tuiMaskedNumberStringToNumber };
