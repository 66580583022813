import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core';
import {
  FormGroup,
  FormGroupDirective,
  ReactiveFormsModule,
} from '@angular/forms';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiDestroyService } from '@taiga-ui/cdk';
import {
  TuiButtonModule,
  TuiNotificationModule,
  TuiSizeXL,
  TuiSizeXS,
} from '@taiga-ui/core';
import {
  PolymorpheusContent,
  PolymorpheusModule,
} from '@tinkoff/ng-polymorpheus';
import { takeUntil } from 'rxjs/operators';
import { FORM_CONTROL_PROVIDERS } from '../../abstracts/form-control';

@Component({
  selector: 'ffb-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  viewProviders: FORM_CONTROL_PROVIDERS,
  standalone: true,
  imports: [
    TranslocoDirective,
    ReactiveFormsModule,
    PolymorpheusModule,
    TuiNotificationModule,
    TuiButtonModule,
  ],
  providers: [TuiDestroyService],
})
export class FormComponent implements OnInit, OnChanges {
  private readonly destroy$ = inject(TuiDestroyService);
  private formGroupDirective = inject(FormGroupDirective, {
    optional: true,
    self: true,
  });

  protected form?: FormGroup;

  @Input({ required: true }) name!: string;
  @Input() title?: string;
  @Input() readOnly? = false;
  @Input() loading? = false;
  @Input() error?: unknown;
  @Input() successMessage?: PolymorpheusContent | false;
  @Input() errorMessage?: PolymorpheusContent | false;
  @Input() skeleton? = false;
  @Input() submitButton = true;
  @Input() submitButtonLabel?: string;
  @Input() cancelButtonLabel?: string;
  @Input() buttonsAlignment: 'center' | 'left' | 'right' = 'right';
  @Input() buttonsSize: TuiSizeXL | TuiSizeXS = 'l';
  @Input() displayRequiredFieldsNote = true;

  @Output() cancel = new EventEmitter<void>();

  hasUpdateOnSubmitControls?: boolean;
  hasRequiredFields?: boolean;

  ngOnInit() {
    this.form = this.formGroupDirective?.form;
    this.hasUpdateOnSubmitControls = Object.values(
      this.form?.controls ?? {},
    ).some(({ updateOn }) => updateOn === 'submit');

    this.form?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.error = null;
      this.errorMessage = null;
      this.hasRequiredFields = Object.values(this.form?.controls ?? {}).some(
        ({ errors }) => errors && Object.keys(errors).includes('required'),
      );
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const error = changes.error?.currentValue;
    if (error instanceof HttpErrorResponse) {
      for (const violation of error.error.violations ?? []) {
        this.form?.get(violation.propertyPath)?.setErrors({
          invalid: {
            message: violation.title,
          },
        });
        this.form?.markAllAsTouched();
      }
    }
  }

  onSubmit() {
    if (this.hasUpdateOnSubmitControls && !this.form?.valid) {
      this.form?.markAllAsTouched();
      Object.values(this.form?.controls ?? {}).forEach((control) => {
        control.updateValueAndValidity();
      });
    }
  }

  isValidationError(error: unknown) {
    return error instanceof HttpErrorResponse && error.status === 422;
  }
}
