import { tuiCreateCorrectionMask, TUI_DIGIT_REGEXP } from '@taiga-ui/core';
import { tuiIsCardLengthValid, tuiIsCardNumberValid } from '@taiga-ui/addon-commerce/utils';
const ALLOWED_REGEXP = /[A-Z]| /;
const MAP = {
  А: 'F',
  В: 'D',
  Г: 'U',
  Д: 'L',
  Е: 'T',
  З: 'P',
  И: 'B',
  Й: 'Q',
  К: 'R',
  Л: 'K',
  М: 'V',
  Н: 'Y',
  О: 'J',
  П: 'G',
  Р: 'H',
  С: 'C',
  Т: 'N',
  У: 'E',
  Ф: 'A',
  Ц: 'W',
  Ч: 'X',
  Ш: 'I',
  Щ: 'O',
  Ы: 'S',
  Ь: 'M',
  Я: 'Z'
};
// TODO: delete in v4.0
function toEnglishUppercaseLegacy(char) {
  const uppercase = char.toUpperCase();
  const result = ALLOWED_REGEXP.test(uppercase) ? uppercase : MAP[uppercase];
  return result || null;
}
function toEnglishUppercase(value) {
  return value.toUpperCase().split('').map(char => MAP[char] || char).join('');
}
const TUI_CARD_HOLDER_MASK = {
  mask: /^[a-z\s]+$/i,
  preprocessors: [({
    elementState,
    data
  }) => {
    const {
      value,
      selection
    } = elementState;
    return {
      elementState: {
        selection,
        value: toEnglishUppercase(value)
      },
      data: toEnglishUppercase(data)
    };
  }]
};
/**
 * @deprecated Use {@link TUI_CARD_HOLDER_MASK} with {@link https://github.com/taiga-family/maskito Maskito}
 * TODO: delete in v4.0
 */
const cardHolderMask = tuiCreateCorrectionMask(ALLOWED_REGEXP, toEnglishUppercaseLegacy);

/**
 * @internal
 */
const TUI_CARD_MASK = [TUI_DIGIT_REGEXP, TUI_DIGIT_REGEXP, TUI_DIGIT_REGEXP, TUI_DIGIT_REGEXP, ' ', TUI_DIGIT_REGEXP, TUI_DIGIT_REGEXP, TUI_DIGIT_REGEXP, TUI_DIGIT_REGEXP, ' ', TUI_DIGIT_REGEXP, TUI_DIGIT_REGEXP, TUI_DIGIT_REGEXP, TUI_DIGIT_REGEXP, ' ', TUI_DIGIT_REGEXP, TUI_DIGIT_REGEXP, TUI_DIGIT_REGEXP, TUI_DIGIT_REGEXP, ' ', TUI_DIGIT_REGEXP, TUI_DIGIT_REGEXP, TUI_DIGIT_REGEXP];
const tuiDefaultCardValidator = card => card.length > 11 && tuiIsCardLengthValid(card) && tuiIsCardNumberValid(card);

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_CARD_HOLDER_MASK, TUI_CARD_MASK, cardHolderMask, tuiDefaultCardValidator };
