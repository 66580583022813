<ffb-form-control
  [formControl]="ngControl?.control"
  [type]="type"
  [note]="note"
  [label]="label"
  [labelOutside]="labelOutside"
  [required]="required"
  >
  <ng-template #labelTemplate>
    @if (!labelOutside) {
      <ffb-form-control-label
        [label]="label"
        [required]="required"
      ></ffb-form-control-label>
    }
  </ng-template>

  <ng-template #copyButton>
    @if (copyable) {
      <ffb-copy-button
        [value]="ngControl?.control?.value ?? ''"
      ></ffb-copy-button>
    }
  </ng-template>

  <ng-template #inputPassword>
    <tui-input-password
      [formControl]="ngControl?.control"
      [tuiTextfieldCleaner]="clearable"
      [tuiTextfieldSize]="size"
      [tuiTextfieldLabelOutside]="labelOutside"
      [tuiAutoFocus]="autofocus"
      (focusedChange)="focusedChange.emit($event)"
      [attr.data-test-id]="formControlName"
      >
      <ng-container [ngTemplateOutlet]="labelTemplate"></ng-container>
      <input
        tuiTextfield
        [attr.autocomplete]="autocomplete"
        [attr.maxlength]="maxLength"
        data-test-id="input"
        />
    </tui-input-password>
  </ng-template>

  @switch (type) {
    <!--  password -->
    @case ('password') {
      <ng-container [ngTemplateOutlet]="inputPassword"></ng-container>
    }
    @case ('currentPassword') {
      <ng-container [ngTemplateOutlet]="inputPassword"></ng-container>
    }
    @case ('newPassword') {
      <ng-container [ngTemplateOutlet]="inputPassword"></ng-container>
    }
    @case ('confirmationPassword') {
      <ng-container [ngTemplateOutlet]="inputPassword"></ng-container>
    }
    <!--  cardNumber-->
    @case ('cardNumber') {
      <tui-input-card
        [formControl]="ngControl?.control"
        [autocompleteEnabled]="true"
        [tuiTextfieldSize]="size"
        [tuiTextfieldLabelOutside]="labelOutside"
        [tuiAutoFocus]="autofocus"
        (focusedChange)="focusedChange.emit($event)"
        [attr.data-test-id]="formControlName"
        >
        <ng-container [ngTemplateOutlet]="labelTemplate"></ng-container>
      </tui-input-card>
    }
    <!--  cardExpiry-->
    @case ('cardExpiry') {
      <tui-input-expire
        [formControl]="ngControl?.control"
        [autocompleteEnabled]="true"
        [tuiTextfieldSize]="size"
        [tuiTextfieldLabelOutside]="labelOutside"
        [tuiAutoFocus]="autofocus"
        (focusedChange)="focusedChange.emit($event)"
        [attr.data-test-id]="formControlName"
        >
        <ng-container [ngTemplateOutlet]="labelTemplate"></ng-container>
      </tui-input-expire>
    }
    <!--  cardValidationCode-->
    @case ('cardValidationCode') {
      <tui-input-cvc
        [formControl]="ngControl?.control"
        [autocompleteEnabled]="true"
        [tuiTextfieldSize]="size"
        [tuiTextfieldLabelOutside]="labelOutside"
        [tuiAutoFocus]="autofocus"
        (focusedChange)="focusedChange.emit($event)"
        [attr.data-test-id]="formControlName"
        >
        <ng-container [ngTemplateOutlet]="labelTemplate"></ng-container>
      </tui-input-cvc>
    }
    <!--  textArea -->
    @case ('textArea') {
      <tui-text-area
        [formControl]="ngControl?.control"
        [maxLength]="maxLength"
        [readOnly]="readOnly"
        [tuiTextfieldCleaner]="clearable"
        [tuiTextfieldSize]="size"
        [tuiTextfieldLabelOutside]="labelOutside"
        [tuiTextfieldCustomContent]="copyButton"
        [tuiAutoFocus]="autofocus"
        (focusedChange)="focusedChange.emit($event)"
        [attr.data-test-id]="formControlName"
        class="full-width"
        >
        <ng-container [ngTemplateOutlet]="labelTemplate"></ng-container>
        <textarea
          tuiTextfield
          [attr.type]="inputType"
          [attr.inputmode]="inputMode"
          [attr.autocomplete]="autocomplete"
          [attr.placeholder]="example"
          data-test-id="textarea"
        ></textarea>
      </tui-text-area>
    }
    <!--  phoneNumber -->
    @case ('phoneNumber') {
      <tui-input-phone-international
        [formControl]="ngControl?.control"
        [readOnly]="readOnly"
        [tuiTextfieldCleaner]="clearable"
        [tuiTextfieldSize]="size"
        [tuiTextfieldLabelOutside]="labelOutside"
        [tuiTextfieldCustomContent]="copyButton"
        [tuiAutoFocus]="autofocus"
        (focusedChange)="focusedChange.emit($event)"
        [countries]="countries | tuiSortCountries | async"
        [(countryIsoCode)]="countryIsoCode"
        [attr.data-test-id]="formControlName"
        >
        <ng-container [ngTemplateOutlet]="labelTemplate"></ng-container>
      </tui-input-phone-international>
    }
    <!--  number -->
    @case ('number') {
      <tui-input-number
        [formControl]="ngControl?.control"
        [min]="min"
        [max]="max"
        [readOnly]="readOnly"
        [tuiTextfieldIconLeft]="iconLeft"
        [tuiTextfieldCleaner]="clearable"
        [tuiTextfieldSize]="size"
        [tuiTextfieldLabelOutside]="labelOutside"
        [tuiTextfieldCustomContent]="copyButton"
        [attr.data-test-id]="formControlName"
        [tuiAutoFocus]="autofocus"
        (focusedChange)="focusedChange.emit($event)"
        class="full-width"
        >
        <ng-container [ngTemplateOutlet]="labelTemplate"></ng-container>
        <input
          tuiTextfield
          [tuiTextfieldCleaner]="clearable"
          [attr.type]="inputType"
          [attr.inputmode]="inputMode"
          [attr.autocomplete]="autocomplete"
          [attr.maxlength]="maxLength"
          [attr.placeholder]="example"
          data-test-id="input"
          />
      </tui-input-number>
    }
    <!--  default -->
    @default {
      <tui-input
        [formControl]="ngControl?.control"
        [readOnly]="readOnly"
        [tuiTextfieldIconLeft]="iconLeft"
        [tuiTextfieldCleaner]="clearable"
        [tuiTextfieldSize]="size"
        [tuiTextfieldLabelOutside]="labelOutside"
        [tuiTextfieldCustomContent]="copyButton"
        [attr.data-test-id]="formControlName"
        [tuiAutoFocus]="autofocus"
        (focusedChange)="focusedChange.emit($event)"
        class="full-width"
        >
        <ng-container [ngTemplateOutlet]="labelTemplate"></ng-container>
        <input
          tuiTextfield
          [tuiTextfieldCleaner]="clearable"
          [attr.type]="inputType"
          [attr.inputmode]="inputMode"
          [attr.autocomplete]="autocomplete"
          [attr.maxlength]="maxLength"
          [attr.placeholder]="example"
          data-test-id="input"
          />
      </tui-input>
    }
  }
</ffb-form-control>
