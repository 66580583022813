<ffb-form-control
  [formControl]="ngControl?.control"
  [type]="type"
  [note]="note"
  >
  @if (label) {
    <label [tuiLabel]="radioListLabel">
      <ng-container [ngTemplateOutlet]="radioList"></ng-container>
    </label>
  } @else {
    <ng-container [ngTemplateOutlet]="radioList"></ng-container>
  }

  <ng-template #radioList>
    <div class="flex flex-row flex-wrap gap2 fit" [class.pt1]="!!label">
      @for (item of items; track item; let i = $index) {
        <tui-radio-labeled
          [formControl]="ngControl?.control"
          [item]="item"
          >
          <span attr.data-test-id="{{ formControlName }}-option-{{ i }}" >
            <ng-container *polymorpheusOutlet="(itemContent || defaultItemContent) as text; context: {$implicit: item}">
                {{ text }}
            </ng-container>
          </span>
        </tui-radio-labeled>
      }
    </div>
  </ng-template>

  <ng-template #defaultItemContent let-data>
    {{stringify(data)}}
  </ng-template>

  <ng-template #radioListLabel>
    <ffb-form-control-label
      [label]="label"
      [required]="required"
    ></ffb-form-control-label>
  </ng-template>
</ffb-form-control>
