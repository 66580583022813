<ng-container *transloco="let t">
  <ffb-form-control
    [formControl]="ngControl?.control"
    [type]="type"
    [note]="note"
    [label]="label"
    [labelOutside]="labelOutside"
    [required]="required"
    [readOnly]="readOnly"
  >
    <tui-combo-box
      [tuiTextfieldIconLeft]="iconLeft"
      [tuiTextfieldSize]="size"
      [tuiTextfieldLabelOutside]="labelOutside"
      [tuiTextfieldCleaner]="clearable"
      [formControl]="ngControl?.control"
      [valueContent]="valueContent"
      [stringify]="stringify"
      [identityMatcher]="identityMatcher"
      [strictMatcher]="strictMatcher"
      [readOnly]="readOnly"
      (searchChange)="onSearchChange($event)"
    >
      @if (!labelOutside) {
        <ffb-form-control-label
          [label]="label"
          [required]="required"
        ></ffb-form-control-label>
      }
      <input
        tuiTextfield
        [placeholder]="example"
        [attr.autocomplete]="autocomplete"
        (input)="onSearchChange(extractValueFromEvent($event))"
      />
      @if (withVirtualScroll) {
        <ng-container *tuiDataList>
          <cdk-virtual-scroll-viewport
            tuiScrollable
            class="form-combo-box-scroll-viewport"
            [itemSize]="48"
            [style.height.px]="((filter(items, search)?.length ?? 1) * 48) + 8"
            (indexChange)="list.handleFocusLossIfNecessary()"
          >
            <tui-data-list #list [emptyContent]="emptyContent">
              <button
                *cdkVirtualFor="let item of filter(items, search)"
                #elementRef="elementRef"
                tuiOption
                tuiElement
                [value]="item"
                [disabled]="disabledItemHandler(item)"
              >
                @if (itemContent) {
                  <span class="t-content">
                  <ng-container
                    *polymorpheusOutlet="
                      itemContent as text;
                      context: getContext(item, elementRef)
                    "
                  >
                    {{ text }}
                  </ng-container>
                </span>
                } @else {
                  {{ stringify(item) }}
                }
              </button>
            </tui-data-list>
          </cdk-virtual-scroll-viewport>
        </ng-container>
      } @else {
        <ng-template #defaultItemContent let-data>
          {{stringify(data)}}
        </ng-template>

        <tui-data-list-wrapper
          *tuiDataList
          [items]="filter(items, search)"
          [itemContent]="itemContent || defaultItemContent"
          [emptyContent]="emptyContent"
          [disabledItemHandler]="disabledItemHandler"
        ></tui-data-list-wrapper>
      }
    </tui-combo-box>
  </ffb-form-control>
</ng-container>
