import { TUI_NON_DIGIT_REGEXP, TUI_NON_DIGITS_REGEXP } from '@taiga-ui/core';
import { tuiIsString, tuiClamp } from '@taiga-ui/cdk';
import { TuiCurrencyCode, TuiCurrency } from '@taiga-ui/addon-commerce/enums';
const TUI_EXP_YEAR_MONTH = /^\d{2}[/|.]\d{4}$/;
const TUI_EXP_SAFARI = /^\d{1,4}.\d{1,2}.\d{1,4}$/;
/**
 * @deprecated Use {@link https://maskito.dev/kit/date Date} from {@link https://github.com/taiga-family/maskito Maskito} instead
 * TODO: delete in v4.0
 * Correct expiration date text allowing only valid months
 * @internal
 *
 * @returns MM/YY
 */
function tuiCreateAutoCorrectedExpirePipe() {
  return (conformedValue, {
    rawValue
  }) => {
    // Autofilled with MM/YYYY format
    if (TUI_EXP_YEAR_MONTH.test(rawValue)) {
      conformedValue = `${rawValue.slice(0, 2)}/${rawValue.slice(5)}`;
    }
    // Autofilled with Safari crazy format
    if (rawValue.length > 7 && rawValue.length < 11 && TUI_EXP_SAFARI.test(rawValue)) {
      const array = rawValue.split(TUI_NON_DIGIT_REGEXP);
      // TODO: investigate why the month is sometimes more than 2-length in safari
      const month = array[1].slice(2);
      const year = array.find(({
        length
      }) => length === 4);
      conformedValue = `${'0'.repeat(2 - month.length)}${month}/${year ? year.slice(2) : ''}`;
    }
    const indexesOfPipedChars = [];
    const conformedValueArr = conformedValue.split('');
    if (parseInt(conformedValueArr[0], 10) > 1) {
      conformedValueArr[2] = '/';
      conformedValueArr[1] = conformedValueArr[0];
      conformedValueArr[0] = '0';
      indexesOfPipedChars.push(0);
    }
    return {
      value: conformedValueArr.join(''),
      indexesOfPipedChars
    };
  };
}
function tuiGetCurrencySymbol(currency) {
  switch (currency) {
    case TuiCurrency.Ruble:
    case TuiCurrencyCode.Ruble:
      return '₽';
    case TuiCurrency.Dollar:
    case TuiCurrencyCode.Dollar:
    case TuiCurrency.MexicanPeso:
    case TuiCurrencyCode.MexicanPeso:
      return '$';
    case TuiCurrency.SingaporeDollar:
    case TuiCurrencyCode.SingaporeDollar:
      return 'S$';
    case TuiCurrency.AustralianDollar:
    case TuiCurrencyCode.AustralianDollar:
      return 'A$';
    case TuiCurrency.HongKongDollar:
    case TuiCurrencyCode.HongKongDollar:
      return 'HK$';
    case TuiCurrency.CanadianDollar:
    case TuiCurrencyCode.CanadianDollar:
      return 'C$';
    case TuiCurrency.Euro:
    case TuiCurrencyCode.Euro:
      return '€';
    case TuiCurrency.Pound:
    case TuiCurrencyCode.Pound:
      return '£';
    case TuiCurrency.Baht:
    case TuiCurrencyCode.Baht:
      return '฿';
    case TuiCurrency.TurkishLira:
    case TuiCurrencyCode.TurkishLira:
      return '₺';
    case TuiCurrency.YuanRenminbi:
    case TuiCurrencyCode.YuanRenminbi:
      return 'CN¥';
    case TuiCurrency.Yen:
    case TuiCurrencyCode.Yen:
      return '¥';
    case TuiCurrency.IsraeliShekel:
    case TuiCurrencyCode.IsraeliShekel:
      return '₪';
    case TuiCurrency.IndianRupee:
    case TuiCurrencyCode.IndianRupee:
      return '₹';
    case TuiCurrency.SwissFranc:
    case TuiCurrencyCode.SwissFranc:
      return '₣';
    case TuiCurrency.ArmenianDram:
    case TuiCurrencyCode.ArmenianDram:
      return '֏';
    case TuiCurrency.Won:
    case TuiCurrencyCode.Won:
      return '₩';
    case TuiCurrency.Tenge:
    case TuiCurrencyCode.Tenge:
      return '₸';
    case TuiCurrency.Hryvnia:
    case TuiCurrencyCode.Hryvnia:
      return '₴';
    case TuiCurrency.UzbekSum:
    case TuiCurrencyCode.UzbekSum:
      return "So'm";
    case TuiCurrency.KyrgyzstanSom:
    case TuiCurrencyCode.KyrgyzstanSom:
      return 'c';
    case TuiCurrency.Dirham:
    case TuiCurrencyCode.Dirham:
      return 'Dh';
    case TuiCurrency.TajikistaniSomoni:
    case TuiCurrencyCode.TajikistaniSomoni:
      return 'SM';
    case TuiCurrency.MalaysianRinggit:
    case TuiCurrencyCode.MalaysianRinggit:
      return 'RM';
    case TuiCurrency.BelarusianRuble:
    case TuiCurrencyCode.BelarusianRuble:
      return 'Br';
    case TuiCurrency.GeorgianLari:
    case TuiCurrencyCode.GeorgianLari:
      return '₾';
    case TuiCurrency.AzerbaijaniManat:
    case TuiCurrencyCode.AzerbaijaniManat:
      return '₼';
    case TuiCurrency.SriLankanRupee:
    case TuiCurrencyCode.SriLankanRupee:
      return 'Rs.';
    case TuiCurrency.SerbianDinar:
    case TuiCurrencyCode.SerbianDinar:
      return 'DIN';
    case TuiCurrency.SaudiRiyal:
    case TuiCurrencyCode.SaudiRiyal:
      return 'SR';
    case TuiCurrency.MongolianTugrik:
    case TuiCurrencyCode.MongolianTugrik:
      return '₮';
    case TuiCurrency.SouthAfricanRand:
    case TuiCurrencyCode.SouthAfricanRand:
      return 'R';
    case TuiCurrency.IranianRial:
    case TuiCurrencyCode.IranianRial:
      return 'IR';
    case TuiCurrency.IndonesianRupiah:
    case TuiCurrencyCode.IndonesianRupiah:
      return 'Rp';
    case TuiCurrency.VietnameseDong:
    case TuiCurrencyCode.VietnameseDong:
      return 'đ';
    case TuiCurrency.NewTurkmenManat:
    case TuiCurrencyCode.NewTurkmenManat:
      return 'TMT';
    case TuiCurrency.BrazilianReal:
    case TuiCurrencyCode.BrazilianReal:
      return 'R$';
    default:
      return null;
  }
}
function stringifyCurrency(currency) {
  return currency === null || tuiIsString(currency) ? currency || '' : String(currency).padStart(3, '0');
}
function tuiFormatCurrency(currency) {
  const stringifiedCurrency = stringifyCurrency(currency);
  return tuiGetCurrencySymbol(stringifiedCurrency) || stringifiedCurrency;
}

// cspell:disable
const TUI_CODE_DICTIONARY = {
  [TuiCurrency.Ruble]: TuiCurrencyCode.Ruble,
  [TuiCurrency.Dollar]: TuiCurrencyCode.Dollar,
  [TuiCurrency.MexicanPeso]: TuiCurrencyCode.MexicanPeso,
  [TuiCurrency.SingaporeDollar]: TuiCurrencyCode.SingaporeDollar,
  [TuiCurrency.AustralianDollar]: TuiCurrencyCode.AustralianDollar,
  [TuiCurrency.HongKongDollar]: TuiCurrencyCode.HongKongDollar,
  [TuiCurrency.CanadianDollar]: TuiCurrencyCode.CanadianDollar,
  [TuiCurrency.Euro]: TuiCurrencyCode.Euro,
  [TuiCurrency.Pound]: TuiCurrencyCode.Pound,
  [TuiCurrency.Baht]: TuiCurrencyCode.Baht,
  [TuiCurrency.TurkishLira]: TuiCurrencyCode.TurkishLira,
  [TuiCurrency.YuanRenminbi]: TuiCurrencyCode.YuanRenminbi,
  [TuiCurrency.Yen]: TuiCurrencyCode.Yen,
  [TuiCurrency.IsraeliShekel]: TuiCurrencyCode.IsraeliShekel,
  [TuiCurrency.IndianRupee]: TuiCurrencyCode.IndianRupee,
  [TuiCurrency.SwissFranc]: TuiCurrencyCode.SwissFranc,
  [TuiCurrency.ArmenianDram]: TuiCurrencyCode.ArmenianDram,
  [TuiCurrency.Won]: TuiCurrencyCode.Won,
  [TuiCurrency.Tenge]: TuiCurrencyCode.Tenge,
  [TuiCurrency.Hryvnia]: TuiCurrencyCode.Hryvnia,
  [TuiCurrency.UzbekSum]: TuiCurrencyCode.UzbekSum,
  [TuiCurrency.KyrgyzstanSom]: TuiCurrencyCode.KyrgyzstanSom,
  [TuiCurrency.Dirham]: TuiCurrencyCode.Dirham,
  [TuiCurrency.TajikistaniSomoni]: TuiCurrencyCode.TajikistaniSomoni,
  [TuiCurrency.MalaysianRinggit]: TuiCurrencyCode.MalaysianRinggit,
  [TuiCurrency.BelarusianRuble]: TuiCurrencyCode.BelarusianRuble,
  [TuiCurrency.GeorgianLari]: TuiCurrencyCode.GeorgianLari,
  [TuiCurrency.AzerbaijaniManat]: TuiCurrencyCode.AzerbaijaniManat,
  [TuiCurrency.SriLankanRupee]: TuiCurrencyCode.SriLankanRupee,
  [TuiCurrency.SerbianDinar]: TuiCurrencyCode.SerbianDinar,
  [TuiCurrency.SaudiRiyal]: TuiCurrencyCode.SaudiRiyal,
  [TuiCurrency.MongolianTugrik]: TuiCurrencyCode.MongolianTugrik,
  [TuiCurrency.SouthAfricanRand]: TuiCurrencyCode.SouthAfricanRand,
  [TuiCurrency.IranianRial]: TuiCurrencyCode.IranianRial,
  [TuiCurrency.IndonesianRupiah]: TuiCurrencyCode.IndonesianRupiah,
  [TuiCurrency.VietnameseDong]: TuiCurrencyCode.VietnameseDong,
  [TuiCurrency.NewTurkmenManat]: TuiCurrencyCode.NewTurkmenManat,
  [TuiCurrency.BrazilianReal]: TuiCurrencyCode.BrazilianReal
};
function tuiGetCodeByCurrency(code) {
  var _a;
  return (_a = TUI_CODE_DICTIONARY[code]) !== null && _a !== void 0 ? _a : null;
}

// cspell:disable
const TUI_CURRENCY_DICTIONARY = {
  [TuiCurrencyCode.Ruble]: TuiCurrency.Ruble,
  [TuiCurrencyCode.Dollar]: TuiCurrency.Dollar,
  [TuiCurrencyCode.MexicanPeso]: TuiCurrency.MexicanPeso,
  [TuiCurrencyCode.SingaporeDollar]: TuiCurrency.SingaporeDollar,
  [TuiCurrencyCode.SingaporeDollar]: TuiCurrency.SingaporeDollar,
  [TuiCurrencyCode.AustralianDollar]: TuiCurrency.AustralianDollar,
  [TuiCurrencyCode.HongKongDollar]: TuiCurrency.HongKongDollar,
  [TuiCurrencyCode.CanadianDollar]: TuiCurrency.CanadianDollar,
  [TuiCurrencyCode.Euro]: TuiCurrency.Euro,
  [TuiCurrencyCode.Pound]: TuiCurrency.Pound,
  [TuiCurrencyCode.Baht]: TuiCurrency.Baht,
  [TuiCurrencyCode.TurkishLira]: TuiCurrency.TurkishLira,
  [TuiCurrencyCode.YuanRenminbi]: TuiCurrency.YuanRenminbi,
  [TuiCurrencyCode.Yen]: TuiCurrency.Yen,
  [TuiCurrencyCode.IsraeliShekel]: TuiCurrency.IsraeliShekel,
  [TuiCurrencyCode.IsraeliShekel]: TuiCurrency.IsraeliShekel,
  [TuiCurrencyCode.IndianRupee]: TuiCurrency.IndianRupee,
  [TuiCurrencyCode.SwissFranc]: TuiCurrency.SwissFranc,
  [TuiCurrencyCode.ArmenianDram]: TuiCurrency.ArmenianDram,
  [TuiCurrencyCode.Won]: TuiCurrency.Won,
  [TuiCurrencyCode.Tenge]: TuiCurrency.Tenge,
  [TuiCurrencyCode.Hryvnia]: TuiCurrency.Hryvnia,
  [TuiCurrencyCode.UzbekSum]: TuiCurrency.UzbekSum,
  [TuiCurrencyCode.KyrgyzstanSom]: TuiCurrency.KyrgyzstanSom,
  [TuiCurrencyCode.Dirham]: TuiCurrency.Dirham,
  [TuiCurrencyCode.TajikistaniSomoni]: TuiCurrency.TajikistaniSomoni,
  [TuiCurrencyCode.MalaysianRinggit]: TuiCurrency.MalaysianRinggit,
  [TuiCurrencyCode.BelarusianRuble]: TuiCurrency.BelarusianRuble,
  [TuiCurrencyCode.GeorgianLari]: TuiCurrency.GeorgianLari,
  [TuiCurrencyCode.AzerbaijaniManat]: TuiCurrency.AzerbaijaniManat,
  [TuiCurrencyCode.SriLankanRupee]: TuiCurrency.SriLankanRupee,
  [TuiCurrencyCode.SerbianDinar]: TuiCurrency.SerbianDinar,
  [TuiCurrencyCode.SaudiRiyal]: TuiCurrency.SaudiRiyal,
  [TuiCurrencyCode.MongolianTugrik]: TuiCurrency.MongolianTugrik,
  [TuiCurrencyCode.SouthAfricanRand]: TuiCurrency.SouthAfricanRand,
  [TuiCurrencyCode.IranianRial]: TuiCurrency.IranianRial,
  [TuiCurrencyCode.IndonesianRupiah]: TuiCurrency.IndonesianRupiah,
  [TuiCurrencyCode.VietnameseDong]: TuiCurrency.VietnameseDong,
  [TuiCurrencyCode.NewTurkmenManat]: TuiCurrency.NewTurkmenManat,
  [TuiCurrencyCode.BrazilianReal]: TuiCurrency.BrazilianReal
};
function tuiGetCurrencyByCode(currency) {
  var _a;
  return (_a = TUI_CURRENCY_DICTIONARY[currency]) !== null && _a !== void 0 ? _a : null;
}

// https://en.wikipedia.org/wiki/Payment_card_number#Issuer_identification_number_(IIN)
const TUI_BIN_TABLE = [['mir', 2200, 2204], ['mastercard', 2221, 2720], ['jcb', 3528, 3589], ['amex', 34], ['amex', 37], ['dinersclub', 36], ['electron', 4026], ['electron', 4175], ['electron', 4405], ['electron', 4508], ['electron', 4844], ['electron', 4913], ['electron', 4917], ['visa', 4], ['verve', 506099, 506198], ['verve', 507865, 507964], ['maestro', 5018], ['maestro', 5020], ['maestro', 5038], ['maestro', 5090], ['maestro', 5890], ['maestro', 5893], ['rupay', 508], ['maestro', 50], ['mastercard', 51, 55], ['maestro', 56], ['maestro', 58], ['mastercard', 5], ['maestro', 6000], ['maestro', 6304], ['maestro', 6759, 6763], ['verve', 650002, 650027], ['maestro', 676770], ['maestro', 676774], ['discover', 644, 649], ['discover', 6011], ['rupay', 60], ['unionpay', 62], ['discover', 65], ['maestro', 6], ['rupay', 81, 82], ['uzcard', 8600], ['humo', 9860]];
function tuiGetPaymentSystem(cardNumber, supported) {
  var _a;
  if (!cardNumber) {
    return null;
  }
  const row = TUI_BIN_TABLE.find(([paymentSystem, start, end = start]) => {
    const cardNumberNumeric = Number.parseInt(cardNumber.slice(0, String(start).length), 10);
    const match = cardNumberNumeric >= start && cardNumberNumeric <= end;
    return match && (!(supported === null || supported === void 0 ? void 0 : supported.length) || supported.includes(paymentSystem));
  });
  return (_a = row === null || row === void 0 ? void 0 : row[0]) !== null && _a !== void 0 ? _a : null;
}
function tuiIsMaestro(three, two, one) {
  if (one === 6) {
    return true;
  }
  if (two === 50 || two > 55 && two < 59) {
    return true;
  }
  if (three < 500) {
    return false;
  }
  return three < 510;
}
function tuiIsMastercard(four, two, one) {
  if (one === 5) {
    return true;
  }
  if (two < 10) {
    return false;
  }
  if (two > 50 && two < 56) {
    return true;
  }
  if (four < 1000) {
    return false;
  }
  return four > 2220 && four < 2721;
}
function tuiIsMir(four) {
  return four > 2199 && four < 2205;
}
function tuiIsElectron(four) {
  switch (four) {
    case 4026:
    case 4175:
    case 4405:
    case 4508:
    case 4844:
    case 4913:
    case 4917:
      return true;
    default:
      return false;
  }
}
function tuiIsVisa(one) {
  return one === 4;
}

/**
 * Validates card number length using payment system dictionary
 */
function tuiIsCardLengthValid(cardNumber) {
  const {
    length
  } = cardNumber;
  const paymentSystem = tuiGetPaymentSystem(cardNumber);
  switch (paymentSystem) {
    case 'electron':
      return length === 16;
    case 'maestro':
      return length > 11 && length < 20;
    case 'mastercard':
    case 'mir':
      return length > 15 && length < 20;
    case 'visa':
      return length > 12 && length < 20;
    default:
      return length > 8 && length < 20;
  }
}

/**
 * Validates card number using Luhn algorithm
 */
function tuiIsCardNumberValid(value) {
  const cardNumber = String(value).replace(TUI_NON_DIGITS_REGEXP, '');
  const {
    length
  } = cardNumber;
  const arr = cardNumber.split('').map((char, index) => {
    const digit = parseInt(char, 10);
    if ((index + length) % 2 === 0) {
      const digitX2 = digit * 2;
      return digitX2 > 9 ? digitX2 - 9 : digitX2;
    }
    return digit;
  });
  return !(arr.reduce((a, b) => a + b, 0) % 10);
}
function tuiIsExpireValid(expire, today = new Date()) {
  const currentMonth = today.getMonth() + 1;
  const currentYear = today.getFullYear() - 2000;
  const expireMonth = tuiClamp(parseInt(expire.slice(0, 2), 10), 1, 12);
  const expireYear = tuiClamp(parseInt(expire.slice(-2), 10), 0, 99);
  return expireYear > currentYear || currentYear === expireYear && expireMonth >= currentMonth;
}

// TODO: Make it a token in 4.0, think about mono icons for card component
const TUI_PAYMENT_SYSTEM_ICONS = {
  mir: 'tuiIconMirMono',
  visa: 'tuiIconVisaMono',
  electron: 'tuiIconElectronMono',
  mastercard: 'tuiIconMastercard',
  maestro: 'tuiIconMaestro',
  amex: 'tuiIconAmex',
  dinersclub: 'tuiIconDinersClub',
  discover: 'tuiIconDiscover',
  humo: 'tuiIconHumo',
  jcb: 'tuiIconJCB',
  rupay: 'tuiIconRuPay',
  unionpay: 'tuiIconUnionPay',
  uzcard: 'tuiIconUzcard',
  verve: 'tuiIconVerve'
};

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_BIN_TABLE, TUI_CODE_DICTIONARY, TUI_CURRENCY_DICTIONARY, TUI_PAYMENT_SYSTEM_ICONS, tuiCreateAutoCorrectedExpirePipe, tuiFormatCurrency, tuiGetCodeByCurrency, tuiGetCurrencyByCode, tuiGetCurrencySymbol, tuiGetPaymentSystem, tuiIsCardLengthValid, tuiIsCardNumberValid, tuiIsElectron, tuiIsExpireValid, tuiIsMaestro, tuiIsMastercard, tuiIsMir, tuiIsVisa };
