<ffb-form-control
  [formControl]="ngControl?.control"
  [type]="type"
  [note]="note"
  [label]="label"
  [labelOutside]="labelOutside"
  [required]="required"
  [class.block]="fitMaxItemLabelWidth"
  [style.width]="fitMaxItemLabelWidth ? getWidth(items) : null"
>
  <tui-select
    [formControl]="ngControl?.control"
    [tuiTextfieldCleaner]="clearable"
    [tuiTextfieldIconLeft]="iconLeft"
    [tuiTextfieldSize]="size"
    [tuiTextfieldLabelOutside]="labelOutside"
    [identityMatcher]="identityMatcher"
    [valueContent]="valueContent"
    [stringify]="stringify"
    [readOnly]="readOnly"
  >
    @if (!labelOutside) {
    <ffb-form-control-label
      [label]="label"
      [required]="required"
    ></ffb-form-control-label>
    }

    <ng-template #defaultItemContent let-data>
      {{stringify(data)}}
    </ng-template>

    <tui-data-list-wrapper
      *tuiDataList
      [items]="items"
      [itemContent]="itemContent || defaultItemContent"
      [emptyContent]="emptyContent"
      [disabledItemHandler]="disabledItemHandler"
    ></tui-data-list-wrapper>
  </tui-select>
</ffb-form-control>
