import {
  AfterContentInit,
  Attribute,
  Directive,
  Input,
  Optional,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  ControlContainer,
  FormGroupDirective,
  NgControl,
} from '@angular/forms';
import { Translation, TranslocoService } from '@ngneat/transloco';
import { TuiSizeL, TuiSizeS } from '@taiga-ui/core';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';
import isPlainObject from 'lodash.isplainobject';
import { NOOP_VALUE_ACCESSOR } from '../utils/valueAccessors';

export const PASSWORD_REQUIRED_LENGTH = 6;
export const FORM_CONTROL_PROVIDERS = [
  {
    provide: ControlContainer,
    useExisting: FormGroupDirective,
  },
];

const getFormControlName = (control?: AbstractControl | null) => {
  if (!control) {
    return null;
  }
  const parentControls = control?.parent?.controls ?? {};
  return (
    Object.keys(parentControls).find(
      // @ts-expect-error: Element implicitly has an any type
      (name) => control === parentControls[name],
    ) || null
  );
};

@Directive({})
export abstract class AbstractFormControl implements AfterContentInit {
  public ngControl = inject(NgControl, { optional: true });
  protected translocoService = inject(TranslocoService);

  @Input() readOnly? = false;
  @Input() size?: TuiSizeL | TuiSizeS = 'l';

  formControlName?: null | number | string = null;
  formControlParentName?: null | number | string = null;

  _type? = '';
  _label?: PolymorpheusContent;
  _labelOutside = false;
  _example?: string;
  _note?: string;

  constructor(
    @Optional() @Attribute('required') private _required: unknown,
    @Optional() @Attribute('autofocus') private _autofocus: unknown,
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = NOOP_VALUE_ACCESSOR;
    }
    this.translocoService
      .selectTranslation('forms')
      .pipe(takeUntilDestroyed())
      .subscribe();
  }

  ngAfterContentInit() {
    this.formControlName = this.ngControl?.name;
    if (!this.formControlName) {
      this.formControlName = getFormControlName(this.ngControl?.control);
    }
    this.formControlParentName = getFormControlName(
      this.ngControl?.control?.parent,
    );
  }

  private get translations(): Translation {
    return this.translocoService.getTranslation(
      this.translocoService.getActiveLang(),
    );
  }

  get type() {
    return this._type;
  }

  @Input() set type(value) {
    this._type = value;
  }

  get required() {
    return (
      this._required !== undefined &&
      this._required !== null &&
      this._required !== false
    );
  }

  @Input() set required(required) {
    this._required = required;
  }

  get autofocus() {
    return (
      this._autofocus !== undefined &&
      this._autofocus !== null &&
      this._autofocus !== false
    );
  }

  @Input() set autofocus(autofocus) {
    this._autofocus = autofocus;
  }

  get label(): PolymorpheusContent {
    if (this._label || this._label === null) {
      return this._label;
    } else if (
      this.translations[`forms.fields.${this.formControlName}.label`]
    ) {
      return this.translocoService.translate(
        `forms.fields.${this.formControlName}.label`,
      );
    } else if (
      this.translations[`forms.fields.${this.formControlParentName}.label`]
    ) {
      return this.translocoService.translate(
        `forms.fields.${this.formControlParentName}.label`,
      );
    } else if (this.translations[`forms.fields.${this.type}.label`]) {
      return this.translocoService.translate(`forms.fields.${this.type}.label`);
    }

    return null;
  }

  @Input() set label(label) {
    this._label = label;
  }

  get labelOutside() {
    return this._label === null || this.size === 's' || this._labelOutside;
  }

  @Input() set labelOutside(labelOutside) {
    this._labelOutside = labelOutside;
  }

  get example(): string {
    if (this._example) {
      return this._example;
    }

    const translationKey = `forms.fields.${this.type}.example`;

    return this.translations[translationKey]
      ? this.translocoService.translate(translationKey)
      : '';
  }

  @Input() set example(example) {
    this._example = example;
  }

  get note(): string {
    if (this._note) {
      return this._note;
    }

    const translationKey = `forms.fields.${this.type}.note`;

    return this.translations[translationKey]
      ? this.translocoService.translate(translationKey, {
          requiredLength: PASSWORD_REQUIRED_LENGTH,
        })
      : '';
  }

  @Input() set note(note) {
    this._note = note;
  }

  private getErrorTranslationKey(errorKey: string): string {
    let translationKey = 'forms.errors.fields.invalid';

    for (const key of [
      `forms.fields.${this.type}.errors.${errorKey}`,
      `forms.errors.fields.${errorKey}`,
    ]) {
      if (this.translations[key]) {
        translationKey = key;
        break;
      }
    }

    return translationKey;
  }

  get errors(): string[] {
    const control = this.ngControl?.control;

    if (!(control?.invalid && control?.touched)) {
      return [];
    }

    const errors = Object.entries(control?.errors ?? {});

    return errors.map(
      ([errorKey, errorData]) =>
        errorData?.message ||
        this.translocoService.translate(
          this.getErrorTranslationKey(errorKey),
          isPlainObject(errorData) ? errorData : {},
        ),
    );
  }
}
