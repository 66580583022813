<ng-container *transloco="let t">
  <ffb-form-control
    [formControl]="ngControl?.control"
    [type]="type"
    [note]="note"
    >
    <ffb-form-control-label
      [label]="label"
      [required]="required"
    ></ffb-form-control-label>

    @if (!ngControl?.control?.value) {
      <tui-input-files
        [formControl]="ngControl?.control"
        [accept]="accept"
        [maxFileSize]="maxFileSize"
        [link]="t('forms.actions.selectAFile')"
        [label]="t('forms.actions.orDropItHere')"
        (reject)="onReject($event)"
      ></tui-input-files>
    }

    <tui-files class="tui-space_top-1">
      @if (ngControl?.control?.valueChanges | async; as file) {
        <tui-file
          [file]="file"
          (removed)="removeFile()"
        ></tui-file>
      }

      @if (rejectedFile$ | async; as file) {
        <tui-file
          state="error"
          [file]="file"
          [state]="state"
          (removed)="clearRejected()"
        ></tui-file>
      }
    </tui-files>
  </ffb-form-control>
</ng-container>
