import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
} from '@angular/core';
import {
  AbstractControl,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {
  TuiInputCVCModule,
  TuiInputCardModule,
  TuiInputExpireModule,
} from '@taiga-ui/addon-commerce';
import {
  TuiAutoFocusModule,
  TuiDestroyService,
  TuiInputMode,
  TuiInputType,
} from '@taiga-ui/cdk';
import {
  TuiPrimitiveTextfieldModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import { TuiCountryIsoCode } from '@taiga-ui/i18n';
import {
  TuiInputModule,
  TuiInputNumberModule,
  TuiInputPasswordModule,
  TuiInputPhoneInternationalModule,
  TuiSortCountriesPipeModule,
  TuiTextAreaModule,
} from '@taiga-ui/kit';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { takeUntil, throttleTime } from 'rxjs/operators';
import { CopyButtonComponent } from '@lancelot-frontend/components';
import {
  AbstractFormControl,
  FORM_CONTROL_PROVIDERS,
  PASSWORD_REQUIRED_LENGTH,
} from '../../abstracts/form-control';
import { FormControlComponent } from '../form-control/form-control.component';
import { FormControlLabelComponent } from '../form-control-label/form-control-label.component';

type ValueOf<T> = T[keyof T];

export const formInputTypes = {
  text: 'text',
  email: 'email',
  newEmail: 'newEmail',
  confirmationEmail: 'confirmationEmail',
  password: 'password',
  currentPassword: 'currentPassword',
  newPassword: 'newPassword',
  confirmationPassword: 'confirmationPassword',
  firstName: 'firstName',
  lastName: 'lastName',
  phoneNumber: 'phoneNumber',
  organizationName: 'organizationName',
  organizationTitle: 'organizationTitle',
  streetAddress: 'streetAddress',
  addressLine1: 'addressLine1',
  addressLine2: 'addressLine2',
  addressLine3: 'addressLine3',
  zipcode: 'zipcode',
  city: 'city',
  cedex: 'cedex',
  country: 'country',
  countryName: 'countryName',
  cardOwnerName: 'cardOwnerName',
  cardNumber: 'cardNumber',
  cardExpiry: 'cardExpiry',
  cardValidationCode: 'cardValidationCode',
  transactionCurrency: 'transactionCurrency',
  transactionAmount: 'transactionAmount',
  url: 'url',
  search: 'search',
  textArea: 'textArea',
  number: 'number',
  oneTimeCode: 'oneTimeCode',
} as const;
export type TFormInputType = ValueOf<typeof formInputTypes>;

export const formInputAutocompletes: Record<
  TFormInputType,
  'do-not-autofill' | HTMLInputElement['autocomplete']
> = {
  email: 'email',
  newEmail: 'email',
  confirmationEmail: 'do-not-autofill',
  password: 'current-password',
  currentPassword: 'current-password',
  newPassword: 'new-password',
  confirmationPassword: 'new-password',
  firstName: 'given-name',
  lastName: 'family-name',
  phoneNumber: 'tel',
  organizationName: 'organization',
  // @ts-expect-error organization-title does exist: https://developer.mozilla.org/fr/docs/Web/HTML/Attributes/autocomplete#organization-title
  organizationTitle: 'organization-title',
  streetAddress: 'street-address',
  addressLine1: 'address-line1',
  addressLine2: 'address-line2',
  addressLine3: 'address-line3',
  zipcode: 'postal-code',
  city: 'address-level2',
  cedex: 'address-level3',
  country: 'country',
  countryName: 'country-name',
  cardOwnerName: 'cc-name',
  cardNumber: 'cc-number',
  cardExpiry: 'cc-exp',
  cardValidationCode: 'cc-csc',
  transactionCurrency: 'transaction-currency',
  transactionAmount: 'transaction-amount',
  text: 'off',
  url: 'off',
  search: 'off',
  textArea: 'off',
  number: 'off',
  oneTimeCode: 'one-time-code',
} as const;
export type TFormInputAutocomplete =
  | 'do-not-autofill'
  | ValueOf<typeof formInputAutocompletes>;

@Component({
  selector: 'ffb-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
  providers: [TuiDestroyService],
  viewProviders: FORM_CONTROL_PROVIDERS,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    NgTemplateOutlet,
    ReactiveFormsModule,
    TuiAutoFocusModule,
    TuiPrimitiveTextfieldModule,
    TuiTextfieldControllerModule,
    TuiInputModule,
    TuiInputPasswordModule,
    TuiInputNumberModule,
    TuiInputCardModule,
    TuiInputExpireModule,
    TuiInputCVCModule,
    TuiInputPhoneInternationalModule,
    TuiTextAreaModule,
    TuiSortCountriesPipeModule,
    FormControlComponent,
    FormControlLabelComponent,
    CopyButtonComponent,
  ],
})
export class FormInputComponent
  extends AbstractFormControl
  implements AfterContentInit
{
  private readonly destroy$ = inject(TuiDestroyService);

  readonly countries = Object.values(TuiCountryIsoCode);
  countryIsoCode = TuiCountryIsoCode.FR;

  @Output() focusedChange = new EventEmitter<boolean>();

  @Input() clearable? = false;
  @Input() copyable? = false;
  @Input() min?: null | number = null;
  @Input() max?: null | number = null;

  _type?: TFormInputType;
  _inputType?: TuiInputType;
  _inputMode?: TuiInputMode;
  _autocomplete?: TFormInputAutocomplete;
  _maxLength?: null | number;
  _iconLeft?: null | string;

  ngAfterContentInit(): void {
    super.ngAfterContentInit();
    this.addValidators();
    this.addValueTransformers();
  }

  addValidators(): void {
    if (
      this.type === 'email' ||
      this.type === 'newEmail' ||
      this.type === 'confirmationEmail'
    ) {
      this.ngControl?.control?.addValidators(Validators.email);
    }
    if (this.type === 'newPassword') {
      this.ngControl?.control?.addValidators(
        Validators.minLength(PASSWORD_REQUIRED_LENGTH),
      );
    }
    if (this.type === 'phoneNumber') {
      const phoneUtil = PhoneNumberUtil.getInstance();
      const phoneNumberValidator = (
        control: AbstractControl,
      ): ValidationErrors | null => {
        let isValid;
        try {
          isValid = phoneUtil.isValidNumberForRegion(
            phoneUtil.parse(control.value, this.countryIsoCode),
            this.countryIsoCode,
          );
        } catch {
          isValid = false;
        }
        if (control.value && !isValid) {
          return { invalid: { value: control.value } };
        }

        return null;
      };
      this.ngControl?.control?.addValidators(phoneNumberValidator);
    }
    this.ngControl?.control?.updateValueAndValidity();
  }

  addValueTransformers() {
    if (this.type === 'phoneNumber') {
      const phoneUtil = PhoneNumberUtil.getInstance();
      const transformValue = (value: string) => {
        try {
          const phoneNumber = phoneUtil.parse(value);
          const countryCode: TuiCountryIsoCode | undefined =
            phoneUtil.getRegionCodeForNumber(phoneNumber) as
              | TuiCountryIsoCode
              | undefined;

          if (
            countryCode &&
            TuiCountryIsoCode[countryCode] &&
            this.countryIsoCode !== TuiCountryIsoCode[countryCode]
          ) {
            this.countryIsoCode = countryCode;
          }

          if (
            phoneNumber &&
            [
              TuiCountryIsoCode.BL,
              TuiCountryIsoCode.FR,
              TuiCountryIsoCode.GF,
              TuiCountryIsoCode.GP,
              TuiCountryIsoCode.MF,
              TuiCountryIsoCode.MQ,
              TuiCountryIsoCode.RE,
              TuiCountryIsoCode.YT,
            ].includes(this.countryIsoCode)
          ) {
            setTimeout(() => {
              this.ngControl?.control?.patchValue(
                `+${phoneNumber.getCountryCode()}0${phoneNumber.getNationalNumber()}`,
                {
                  emitEvent: false,
                },
              );
            });
          }
        } catch {
          // NOOP
        }
      };

      if (this.ngControl?.control?.value) {
        transformValue(this.ngControl?.control?.value);
      }

      this.ngControl?.control?.valueChanges
        .pipe(throttleTime(0), takeUntil(this.destroy$))
        .subscribe(transformValue);
    }
  }

  get type(): TFormInputType {
    if (this._type) {
      return this._type;
    }

    return (
      formInputTypes[(this.formControlName || 'text') as TFormInputType] ||
      'text'
    );
  }

  @Input() set type(type) {
    this._type = type;
  }

  get inputType(): TuiInputType {
    if (this._inputType) {
      return this._inputType;
    }
    switch (this.type) {
      case 'email':
        return 'email';
      case 'url':
        return 'url';
      case 'zipcode':
      case 'number':
        return 'tel';
      default:
        return 'text';
    }
  }

  @Input() set inputType(type) {
    this._inputType = type;
  }

  get inputMode(): TuiInputMode {
    if (this._inputMode) {
      return this._inputMode;
    }

    switch (this.type) {
      case 'email':
        return 'email';
      case 'url':
        return 'url';
      case 'phoneNumber':
        return 'tel';
      case 'number':
      case 'zipcode':
        return 'numeric';
      case 'search':
        return 'search';
      default:
        return 'text';
    }
  }

  @Input() set inputMode(mode) {
    this._inputMode = mode;
  }

  get autocomplete(): TFormInputAutocomplete {
    if (this._autocomplete) {
      return this._autocomplete;
    }

    return formInputAutocompletes[this.type] || 'off';
  }

  @Input() set autocomplete(autocomplete) {
    this._autocomplete = autocomplete;
  }

  get maxLength(): null | number {
    if (this._maxLength) {
      return this._maxLength;
    }

    switch (this.type) {
      case 'email':
      case 'newEmail':
      case 'confirmationEmail':
        return 254;
      case 'password':
      case 'currentPassword':
      case 'newPassword':
      case 'confirmationPassword':
        return 128;
      case 'firstName':
      case 'lastName':
      case 'cardOwnerName':
        return 30;
      case 'organizationName':
        return 104;
      case 'streetAddress':
        return 254;
      case 'addressLine1':
      case 'addressLine2':
      case 'addressLine3':
      case 'city':
      case 'country':
        return 128;
      case 'zipcode':
        return 10;
      case 'cedex':
        return 16;
      default: {
        return null;
      }
    }
  }

  @Input() set maxLength(maxLength) {
    this._maxLength = maxLength;
  }

  get iconLeft(): null | string {
    if (this._iconLeft) {
      return this._iconLeft;
    }

    switch (this.type) {
      case 'search':
        return 'tuiIconSearch';
      default: {
        return null;
      }
    }
  }

  @Input() set iconLeft(icon) {
    this._iconLeft = icon;
  }
}
