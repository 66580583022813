import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoDirective } from '@ngneat/transloco';
import {
  TuiFileLike,
  TuiFileState,
  TuiFilesModule,
  TuiInputFilesModule,
} from '@taiga-ui/kit';
import { Subject } from 'rxjs';
import {
  AbstractFormControl,
  FORM_CONTROL_PROVIDERS,
} from '../../abstracts/form-control';
import { FormControlComponent } from '../form-control/form-control.component';
import { FormControlLabelComponent } from '../form-control-label/form-control-label.component';

@Component({
  selector: 'ffb-form-input-file',
  templateUrl: './form-input-file.component.html',
  styleUrls: ['./form-input-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: FORM_CONTROL_PROVIDERS,
  standalone: true,
  imports: [
    TranslocoDirective,
    FormControlComponent,
    FormsModule,
    ReactiveFormsModule,
    FormControlLabelComponent,
    TuiInputFilesModule,
    TuiFilesModule,
    AsyncPipe,
  ],
})
export class FormInputFileComponent extends AbstractFormControl {
  @Input() accept = '';
  @Input() maxFileSize = 1000000; // (1 MB by default — 1 * 1000 * 1000)
  @Input() state?: TuiFileState = 'normal';

  protected readonly rejectedFile$ = new Subject<TuiFileLike | null>();

  onReject(file: TuiFileLike | readonly TuiFileLike[]): void {
    this.rejectedFile$.next(file as TuiFileLike);
  }

  removeFile(): void {
    this.ngControl?.control?.setValue(null);
  }

  clearRejected(): void {
    this.rejectedFile$.next(null);
  }
}
