import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TuiCheckboxLabeledModule } from '@taiga-ui/kit';
import {
  AbstractFormControl,
  FORM_CONTROL_PROVIDERS,
} from '../../abstracts/form-control';
import { FormControlComponent } from '../form-control/form-control.component';
import { FormControlLabelComponent } from '../form-control-label/form-control-label.component';

@Component({
  selector: 'ffb-form-checkbox',
  templateUrl: './form-checkbox.component.html',
  styleUrls: ['./form-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: FORM_CONTROL_PROVIDERS,
  standalone: true,
  imports: [
    FormControlComponent,
    FormsModule,
    ReactiveFormsModule,
    TuiCheckboxLabeledModule,
    FormControlLabelComponent,
  ],
})
export class FormCheckboxComponent
  extends AbstractFormControl
  implements OnInit
{
  ngOnInit(): void {
    this.addValidators();
  }

  addValidators(): void {
    if (this.required) {
      this.ngControl?.control?.addValidators(Validators.requiredTrue);
    }

    this.ngControl?.control?.updateValueAndValidity();
  }
}
