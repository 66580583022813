<ng-container *transloco="let t">
  <ffb-form-control
    [formControl]="ngControl?.control"
    [type]="type"
    [note]="note"
    [label]="label"
    [labelOutside]="labelOutside"
    [required]="required"
    >
    <tui-input-tag
      [formControl]="ngControl?.control"
      [readOnly]="readOnly"
      [maxLength]="maxLength"
      [uniqueTags]="uniqueTags"
      [editable]="editable"
      [expandable]="expandable"
      [separator]="separator"
      [rows]="rows"
      [tagValidator]="tagValidator"
      [tuiTextfieldCleaner]="clearable"
      [tuiTextfieldSize]="size"
      [tuiTextfieldLabelOutside]="labelOutside"
      [attr.data-test-id]="formControlName"
      >
      @if (!labelOutside) {
        <ffb-form-control-label
          [label]="label"
          [required]="required"
        ></ffb-form-control-label>
      }
    </tui-input-tag>
  </ffb-form-control>
</ng-container>
