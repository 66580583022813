import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
} from '@angular/forms';
import { TranslocoDirective } from '@ngneat/transloco';
import {
  ALWAYS_FALSE_HANDLER,
  TUI_DATE_SEPARATOR,
  TUI_FIRST_DAY,
  TUI_LAST_DAY,
  TuiBooleanHandler,
  TuiDay,
} from '@taiga-ui/cdk';
import {
  TUI_DEFAULT_MARKER_HANDLER,
  TuiMarkerHandler,
  TuiPrimitiveTextfieldModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import { TuiInputDateModule, TuiNamedDay } from '@taiga-ui/kit';
import {
  AbstractFormControl,
  FORM_CONTROL_PROVIDERS,
} from '../../abstracts/form-control';
import { FormControlComponent } from '../form-control/form-control.component';
import { FormControlLabelComponent } from '../form-control-label/form-control-label.component';

@Component({
  selector: 'ffb-form-input-date',
  templateUrl: './form-input-date.component.html',
  styleUrls: ['./form-input-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: TUI_DATE_SEPARATOR, useValue: `/` }],
  viewProviders: FORM_CONTROL_PROVIDERS,
  standalone: true,
  imports: [
    TranslocoDirective,
    FormControlComponent,
    FormsModule,
    ReactiveFormsModule,
    TuiInputDateModule,
    TuiTextfieldControllerModule,
    FormControlLabelComponent,
    TuiPrimitiveTextfieldModule,
  ],
})
export class FormInputDateComponent
  extends AbstractFormControl
  implements OnInit
{
  get type() {
    return 'date';
  }

  @Input() min?: TuiDay = TUI_FIRST_DAY;
  @Input() max?: TuiDay = TUI_LAST_DAY;
  // A list of preset dates for dropdown
  @Input() items?: TuiNamedDay[] = [];
  // A handler that gets a date and returns true if it is disabled.
  @Input() disabledItemHandler?: TuiBooleanHandler<TuiDay> =
    ALWAYS_FALSE_HANDLER;
  // A handler that gets date and returns null or a tuple with circled marker colors
  @Input() markerHandler?: TuiMarkerHandler = TUI_DEFAULT_MARKER_HANDLER;
  @Input() clearable? = false;

  @Output() focusedChange = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.addValidators();
  }

  addValidators(): void {
    if (this.min || this.max) {
      this.ngControl?.control?.addValidators(
        (control: AbstractControl): ValidationErrors | null => {
          if (this.min && control.value && control.value.dayBefore(this.min)) {
            return { beforeMin: { value: control.value } };
          }

          if (this.max && control.value && control.value.dayAfter(this.max)) {
            return { afterMax: { value: control.value } };
          }

          return null;
        },
      );

      this.ngControl?.control?.updateValueAndValidity();
    }
  }
}
